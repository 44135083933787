import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { IManager } from "../../models/manager";
import { RootStoreContext } from "../../stores/rootStore";
import {
  Button,
  Grid,
  Header,
  Segment,
  Form,
  Checkbox,
} from "semantic-ui-react";
import TextInput from "../../common/form/TextInput";
import { FORM_ERROR } from "final-form";
import ErrorMessage from "../layout/ErrorMessage";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired, matchesField } from "revalidate";
import { IDepotsDetails } from "../../models/tenant";

interface IProps {
  tenantId?: string;
  tenantName?: string;
  manager?: IManager;
  isViewDetails?: boolean;
  depot?: IDepotsDetails;
}
const validate = combineValidators({
  name: isRequired("Name"),
  email: isRequired("Email"),
  depotId: isRequired("depotId"),
 department: isRequired("Department"),

  repeatEmail: matchesField(
    "email",
    "repeatEmail"
  )({ message: "Emails do not match" }),
   //
});
const validateUpdate = combineValidators({
  name: isRequired("Name"),
  email: isRequired("Email"),
  department: isRequired("Department")
});

const AddManager: React.FC<IProps> = ({
  tenantId,
  tenantName,
  manager,
  isViewDetails,
  depot
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    addManager,
    updateManager,
    handleUpdateClick,
    handleEmailFlagIfUpdating,
    updateData,
    handleEmailRecipientDataClick,
    handleReceiveEmailFlag,
    receiveEmailFlag,
  } = rootStore.managerStore;
  const {  ListDepot } = rootStore.depotStore;

  useEffect(() => {
    if (isViewDetails) {
      handleEmailFlagIfUpdating(manager!);
    }

    if (tenantId) ListDepot(tenantId);
  }, [ListDepot, tenantId, isViewDetails, handleUpdateClick,handleEmailFlagIfUpdating,manager]);

  const handleEditManager = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: IManager
  ) => {
    event.preventDefault();
    handleUpdateClick();
  };

  return (
    <div>
      <Grid centered>
        <Grid.Column width={15}>
          {isViewDetails ? (
            <Header as="h2" attached="top">
              {updateData ? (
                <div>
                  Edit <u>{manager?.name}</u> Details
                </div>
              ) : (
                <div>
                  View <u>{manager?.name}</u> Details
                </div>
              )}
            </Header>
          ) : (
            <Header as="h2" attached="top">
              Add Manager for: <u>{tenantName}</u>
            </Header>
          )}
          {isViewDetails ? (
            <Segment clearing>
              <FinalForm
                onSubmit={(values: IManager) =>
                  updateManager(values).catch((error) => ({
                    [FORM_ERROR]: error,
                  }))
                }
                initialValues={manager}
                validate={validateUpdate}
                render={({
                  handleSubmit,
                  submitting,
                  submitError,
                  invalid,
                  pristine,
                  dirtySinceLastSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} error>
                    <Field
                      name="id"
                      type="hidden"
                      defaultValue={manager?.id}
                      component={TextInput}
                    />
                    <Field
                      name="tenantId"
                      type="hidden"
                      defaultValue={tenantId}
                      component={TextInput}
                    />
                        <Form.Group widths="equal">
                    <Field
                      label="Name"
                      name="name"
                      placeholder="Name"
                      component={TextInput}
                      readOnly={!updateData}
                    />
                    <Field
                      label="Department"
                      name="department"
                      placeholder="Department"
                      component={TextInput}
                      readOnly={!updateData}
                    />
                    </Form.Group>
                    <Field name="depotId" type="hidden" component={TextInput} />

                    <Form.Group widths="equal">
                      <Field
                        readOnly={!updateData}
                        label="email"
                        name="email"
                        type="email"
                        placeholder="email"
                        component={TextInput}
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <Form.Field>
                        {!updateData ? (
                          <Checkbox
                            toggle
                            readOnly={!updateData}
                            name="receiveEmailFlag"
                            checked={manager?.receiveEmailFlag}
                            label="Receive Email for Unplanned visitor?"
                          />
                        ) : (
                          <Checkbox
                            onClick={handleEmailRecipientDataClick}
                            toggle
                            name="receiveEmailFlag"
                            checked={receiveEmailFlag}
                            label="Receive Email for Unplanned visitor?"
                          />
                        )}
                      </Form.Field>
                    </Form.Group>
                    {updateData ? (
                      <Button
                        fluid
                        icon="save"
                        loading={submitting}
                        disabled={
                          (invalid && !dirtySinceLastSubmit) || pristine
                        }
                        positive
                        labelPosition="left"
                        content="Save"
                      />
                    ) : (
                      <Button
                        fluid
                        icon="edit"
                        loading={submitting}
                        disabled={
                          (invalid && !dirtySinceLastSubmit) || pristine
                        }
                        primary
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => handleEditManager(event, manager!)}
                        labelPosition="left"
                        content="Update"
                      />
                    )}

                    <br />
                    {submitError && !dirtySinceLastSubmit && (
                      <ErrorMessage error={submitError} />
                    )}
                  </Form>
                )}
              />
            </Segment>
          ) : (
            <Segment clearing>
              <FinalForm
                onSubmit={(values: IManager) =>
                  addManager(values).catch((error) => ({
                    [FORM_ERROR]: error,
                  }))
                }
                initialValues={manager}
                validate={validate}
                render={({
                  handleSubmit,
                  submitting,
                  submitError,
                  invalid,
                  pristine,
                  dirtySinceLastSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} error>
                    {/* <Field
                      label="Depot"
                      name="depotId"
                      options={depots}
                      placeholder="Depot"
                      componentFlag="AddBadge"
                      component={SelectInput}
                    /> */}
                    <Field
                      name="depotId"
                      type="hidden"
                      defaultValue={depot?.id}
                      component={TextInput}
                    />

                    <Field
                      name="tenantId"
                      type="hidden"
                      defaultValue={tenantId}
                      component={TextInput}
                    />
                     <Form.Group widths="equal">
                    <Field
                      label="Name"
                      name="name"
                      placeholder="Name"
                      component={TextInput}
                    />
                          <Field
                      label="Department"
                      name="department"
                      placeholder="Department"
                      component={TextInput}
                    />
                    </Form.Group>
                    <Field name="depotId" type="hidden" component={TextInput} />
                    <Form.Group widths="equal">
                      <Field
                        label="email"
                        name="email"
                        type="email"
                        placeholder="email"
                        component={TextInput}
                      />
                      {!manager ? (
                        <Field
                          label="Repeat Email"
                          name="repeatEmail"
                          type="email"
                          placeholder="Repeat Email"
                          component={TextInput}
                        />
                      ) : null}
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <Checkbox
                          onClick={handleReceiveEmailFlag}
                          toggle
                          name="emailRecipient"
                          checked={receiveEmailFlag}
                          label="Receive Email for Unplanned visitor?"
                        />
                      </Form.Field>
                    </Form.Group>

                    <Button
                      fluid
                      icon="plus"
                      loading={submitting}
                      disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                      positive
                      labelPosition="left"
                      content="Add"
                    />

                    <br />
                    {submitError && !dirtySinceLastSubmit && (
                      <ErrorMessage error={submitError} />
                    )}
                  </Form>
                )}
              />
            </Segment>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default observer(AddManager);
