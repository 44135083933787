import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import App from './App';
import 'semantic-ui-css/semantic.min.css'
import "react-toastify/dist/ReactToastify.css";
import "semantic-ui-css/semantic.min.css";
import { createBrowserHistory } from 'history';
import 'react-widgets/dist/css/react-widgets.css';
import dateFnsLocalizer from 'react-widgets-date-fns';
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import ReactGA from "react-ga";

dateFnsLocalizer()
export const history = createBrowserHistory();

// Initialize google analytics page view tracking
  history.listen(location => {
    ReactGA.initialize('UA-190924065-1');
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

ReactDOM.render(
    <Router history={history}>
        <App />
    </Router>,
    document.getElementById("root"))