import React, { useContext } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Checkbox,
  CheckboxProps,
  Divider,
  Segment,
} from "semantic-ui-react";
import { Field, Form as FinalForm } from "react-final-form";
import TextInput from "../../../common/form/TextInput";
import { RootStoreContext } from "../../../stores/rootStore";
import { IPartner } from "../../../models/bookVisitModel";
import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import UploadFile from "../../fileUpload/uploadFile";
import ErrorMessage from "../../layout/ErrorMessage";
import { combineValidators, isRequired, isNumeric } from "revalidate";
import Dashboard from "../../layout/Dashboard";

const validate = combineValidators({
  organisation: isRequired("Organisation"),
  workId: isRequired("Work ID"),
  name: isRequired("Name"),
  id: isNumeric("workId"),
});

const RegisterPartner = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    registerPartner,
    submitPartnerFile,
    onChangeFlag,
    badgeNumFlag,
    signFlag,
    bulkUploadLoading,
    success,
    axiosResponse,
    axiosResponseSingle,
    errors,
    errorsSingle,
  } = rootStore.partnerStore;

  return (
    <Dashboard>
      <Grid centered>
        <Grid.Column width={15}>
          <Segment loading={bulkUploadLoading}>
            <Header as="h2" style={{ borderWidth: 0 }} attached="top">
              Register a Partner
            </Header>

            <Header style={{ borderWidth: 0 }} as="h3" attached="top">
              Upload a File
            </Header>

            <UploadFile fileUploaded={success.length > 0} />

            <Button
              icon="upload"
              onClick={submitPartnerFile}
              positive
              content="Submit"
              loading={bulkUploadLoading}
            />
            <br />
            <br />
            {errors && <ErrorMessage error={axiosResponse!} text={errors} />}

            <br />
          </Segment>
          <Divider horizontal>Or</Divider>

          <FinalForm
            onSubmit={(values: IPartner) =>
              registerPartner(values).catch((error) => ({
                [FORM_ERROR]: error,
              }))
            }
            validate={validate}
            render={({
              handleSubmit,
              submitting,
              submitError,
              invalid,
              pristine,
              dirtySinceLastSubmit,
            }) => (
              <Form onSubmit={handleSubmit} error loading={submitting}>
                <Header style={{ borderWidth: 0 }} as="h3" attached="top">
                  Enter Partner Details:
                </Header>
                <br />
                <Form.Group widths="equal">
                  <Field
                    label="Work ID"
                    name="workId"
                    type={"number"}
                    placeholder="Work ID"
                    component={TextInput}
                  />
                  <Field
                    label="Name"
                    name="name"
                    placeholder="Name"
                    component={TextInput}
                  />
                  <Field
                    label="Organisation"
                    name="organisation"
                    placeholder="Organisation"
                    component={TextInput}
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Checkbox
                    label="Enter Badge Number?"
                    name="badgeNumFlag"
                    checked={badgeNumFlag}
                    onClick={(
                      event: React.MouseEvent<HTMLInputElement>,
                      data: CheckboxProps
                    ) => onChangeFlag(event, data)}
                    toggle
                  />
                </Form.Group>
                <Form.Group>
                  <Checkbox
                    label="Sign House Rules?"
                    name="signFlag"
                    checked={signFlag}
                    onClick={(
                      event: React.MouseEvent<HTMLInputElement>,
                      data: CheckboxProps
                    ) => onChangeFlag(event, data)}
                    toggle
                  />
                </Form.Group>

                <Button
                  icon="book"
                  loading={submitting}
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  positive
                  content="Register"
                />
              </Form>
            )}
          />

          <br />
          <br />
          {errorsSingle && (
            <ErrorMessage error={axiosResponseSingle!} text={errorsSingle} />
          )}

          <br />
        </Grid.Column>
      </Grid>
    </Dashboard>
  );
};

export default observer(RegisterPartner);
