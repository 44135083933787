import React, { useContext, useEffect, useState } from "react";
import { Grid, Header, Segment, Form, Table, Button } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/rootStore";
import ReportLayout from "../../layout/ReportLayout";
import { Field, Form as FinalForm } from "react-final-form";
import { observer } from "mobx-react-lite";
import SelectInput from "../../../common/form/SelectInput";
import ViewPartner from "./ViewPartner";
import RemovePartnerModal from "../ManagePartners/RemovePartnerModal";
const PartnersRegistered = () => {
  const rootStore = useContext(RootStoreContext);
  const { depots, ListByUserDepot } = rootStore.depotStore;
  const { resetPartnerList, partnerList } = rootStore.reportStore;
  const { openModal, openPartnerModal } = rootStore.modalStore;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    ListByUserDepot().then(() => setLoading(false));
    return () => {
      resetPartnerList();
    };
  }, [setLoading, resetPartnerList,ListByUserDepot]);

  return (
    <ReportLayout>
      <RemovePartnerModal />
      <Segment>
        <FinalForm
          onSubmit={() => {}}
          render={() => (
            <Form error>
              <Field
                label="Depot"
                name="depot"
                placeholder="Depot"
                componentFlag={"viewRegisteredPartners"}
                component={SelectInput}
                options={depots}
                loading={loading}
              />
            </Form>
          )}
        />
      </Segment>
      <Grid columns="2">
        <Grid.Column width="16" className="booked-visits-grid-column">
          <Header as="h2" content="Partner(s) Registered" />
          {partnerList.length > 0 && (
            <div className="booked-visits-tbl">
              <Table color="green">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Work ID</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Organization</Table.HeaderCell>
                    <Table.HeaderCell>Number of Attendances</Table.HeaderCell>
                    <Table.HeaderCell>
                      Should Enter Depot Badge?
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Should Sign House Rules?
                    </Table.HeaderCell>
                    <Table.HeaderCell />
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {partnerList &&
                    partnerList.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{e.workId}</Table.Cell>
                        <Table.Cell>{e.name}</Table.Cell>
                        <Table.Cell>{e.organisation}</Table.Cell>
                        <Table.Cell>{e.attendances}</Table.Cell>
                        <Table.Cell> {e.badgeNumFlag?.toString()}</Table.Cell>
                        <Table.Cell>{e.signFlag?.toString()}</Table.Cell>
                        <Table.Cell>
                          <Button
                            content="Remove"
                            compact
                            color="red"
                            icon="trash"
                            onClick={() => openPartnerModal(e.id)}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            content="View"
                            compact
                            color="orange"
                            icon="eye"
                            onClick={() =>
                              openModal(
                                <ViewPartner isViewDetails={true} partner={e} />
                              )
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          )}
        </Grid.Column>
      </Grid>
    </ReportLayout>
  );
};

export default observer(PartnersRegistered);
