import React, { useContext, useState, useRef, useEffect } from "react";
import { Button, Grid, Image, Form } from "semantic-ui-react";
import logo from "../../assets/visIT_logo.png";
import { RootStoreContext } from "../../stores/rootStore";
import Keyboard from "react-simple-keyboard";
import { observer } from "mobx-react-lite";
import ErrorMessage from "../layout/ErrorMessage";
import "../../style/visitor.css";
import { history } from "../..";

const EnterBadgeNumber = (props) => {
  const rootStore = useContext(RootStoreContext);
  const {
    VisitorSubmitBadgeNumber,
    depotBadgeNum,
    onChangeSwitch,
    errors,clearVisitorData,
    axiosResponse,
  } = rootStore.visitorStore;
  const { depot, getDepot } = rootStore.depotStore;
  const [inputName, setInputName] = useState("");
  const keyboard = useRef();
  const setActiveInput = (event) => setInputName(event.target.id);
  const [layout, setLayout] = useState("default");

  const { match } = props;
  let { depotCode, tenantName } = match.params;

  useEffect(() => {
    if (!depot) {
      getDepot(tenantName, depotCode);
    }
    document.getElementById("badgeNum").focus();
  }, [depotCode, getDepot, depot, tenantName]);

  const handleShift = () =>
    setLayout(layout === "default" ? "shift" : "default");

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    VisitorSubmitBadgeNumber();
  };

  const onChangeForm = (event) => {
    onChangeSwitch(event.target.name, event.target.value);
    keyboard.current.setInput(event.target.value);
  };
  const onChange = (input) => {
    onChangeSwitch(inputName, input);
  };
  
  const BackHomeClick = () => {
    clearVisitorData();
    history.push(`/${tenantName}/${depotCode}`);
  };

  return (
    <div>
         <Button
        style={{ marginBottom: 15, marginLeft: 25, marginTop: 20 }}
        size="big"
        icon="home"
        onClick={BackHomeClick}
        default
        content="Home"
      />
      <Grid columns={2} centered style={{ marginTop: 5 }}>
        <Image
          src={logo}
          style={{
            marginTop: 20,
            marginBottom: 25,
          }}
        />
        <br />
      </Grid>
      <Grid columns={1} centered>
        <Form onSubmit={handleSubmit} error method="post">
          <Grid.Row>
            <Form.Field>
              <input
                required
                style={{ fontSize: 18, borderWidth: 2 }}
                id="badgeNum"
                name="badgeNum"
                type="text"
                value={depotBadgeNum}
                onChange={onChangeForm}
                placeholder="Badge Number"
                onFocus={setActiveInput}
                autoComplete={"off"}
              />
            </Form.Field>
            <Button
              style={{ marginBottom: 15, marginLeft: 25 }}
              size="big"
              icon="sign-in"
              primary
              content="Submit"
            />
          </Grid.Row>
          <Form.Field>
            <Grid.Row>
              {errors && <ErrorMessage error={axiosResponse} text={errors} />}
            </Grid.Row>
          </Form.Field>
        </Form>
        <br />
      </Grid>

      <div className="keyboardPosition">
        <Keyboard
          inputName={inputName}
          useMouseEvents={true}
          keyboardRef={(r) => (keyboard.current = r)}
          layoutName={layout}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </div>
    </div>
  );
};

export default observer(EnterBadgeNumber);
