import React, { useContext, useState } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { Form as FinalForm, Field } from "react-final-form";
import { Grid, Segment, Form, Button, Header } from "semantic-ui-react";
import TextInput from "../../common/form/TextInput";
import { FORM_ERROR } from "final-form";
import { IDepotFormValues } from "../../models/depot";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import { combineValidators, isRequired } from "revalidate";
import ErrorMessage from "../layout/ErrorMessage";

const validate = combineValidators({
  code: isRequired("Code"),
  line1: isRequired("Line 1"),
  line2: isRequired("Line 2"),
  county: isRequired("County"),
  eirCode: isRequired("EirCode"),
});

interface IProps {
  tenantId: string;
  tenantName?: string;
}
const AddDepot: React.FC<IProps> = ({ tenantId, tenantName }) => {
  const rootStore = useContext(RootStoreContext);
  const { AddDepot } = rootStore.depotStore;
  const { user } = rootStore.userStore;
  const [roleArr] = useState(["TenantAdmin", "SuperAdmin"]);


  if (!roleArr.includes(user?.role!)) return <Redirect to="/dashboard" />;
  return (
    <div>
    <Grid centered>
      <Grid.Column width={15}>
        <Header as="h2" attached="top">
          Add Location for: <u>{tenantName}</u>
        </Header>
        <Segment clearing>
          <FinalForm
            onSubmit={(values: IDepotFormValues) =>
              AddDepot(values).catch((error) => ({
                [FORM_ERROR]: error,
              }))
            }
            validate={validate}
            render={({
              handleSubmit,
              submitting,
              submitError,
              invalid,
              pristine,
              dirtySinceLastSubmit,
            }) => (
              <Form onSubmit={handleSubmit} error>
                <Field
                  label="Code"
                  name="code"
                  placeholder="Code"
                  component={TextInput}
                />
                <Field
                  name="tenantId"
                  type="hidden"
                  defaultValue={tenantId}
                  component={TextInput}
                />

                <Form.Group widths="equal">
                  <Field
                    label="Line 1"
                    name="line1"
                    type="text"
                    placeholder="Line 1"
                    component={TextInput}
                  />

                  <Field
                    label="Line 2"
                    name="line2"
                    type="text"
                    placeholder="Line 2"
                    component={TextInput}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Field
                    label="County"
                    name="county"
                    type="text"
                    placeholder="County"
                    component={TextInput}
                  />
                  <Field
                    label="EirCode"
                    name="eirCode"
                    type="text"
                    placeholder="EirCode"
                    component={TextInput}
                  />
                </Form.Group>

           
                <Button
                  fluid
                  icon="plus"
                  loading={submitting}
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                 positive
                 labelPosition='left'
                  content="Add"
                />
                <br/>
                     {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage error={submitError} />
                )}
              </Form>

              
            )}
          />
        </Segment>
      </Grid.Column>
    </Grid>
    </div>
  
  );
};

export default observer(AddDepot);
