import React, { useContext, useEffect } from "react";
import { combineValidators, isRequired} from "revalidate";

import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Checkbox,
} from "semantic-ui-react";
import { Field, Form as FinalForm } from "react-final-form";
import TimeInput from "../../../common/form/TimeInput";
import TextInput from "../../../common/form/TextInput";
import { RootStoreContext } from "../../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { FORM_ERROR } from "final-form";
import { IBookVisitFormData } from "../../../models/bookVisitModel";
import SelectInput from "../../../common/form/SelectInput";
import ManageMeetingLayout from "../../layout/ManageMeetingLayout";


const validate = combineValidators({
  AttendeeFirstName: isRequired("Attendee First Name")  ,
  AttendeeLastName: isRequired("Attendee Last Name") ,
  AttendeeEmail: isRequired("Attendee Email") ,
  AttendeeCompany: isRequired("Attendee Company"),
  date: isRequired("Date") ,
  time: isRequired("Time"),
 
});
const BookMeeting = () => {
  const rootStore = useContext(RootStoreContext);
  const { depots, ListDepot } = rootStore.depotStore;
  const {
    BookMeeting,
    hostFlag,
    changeHostFlag,
    changeBadgeFlag,
    printLabelFlag,
    ChangePrintLabelFlag,
    dateError,
    badgeFlag,
  } = rootStore.meetingStore;
  const { user } = rootStore.userStore;

  useEffect(() => {
    ListDepot(user?.tenantId!);
  }, [ListDepot, user]);

  return (
    <ManageMeetingLayout>
      <br />
      <br />
      <br />
      <Grid centered>
        <Grid.Column width={15}>
          <Header as="h2" style={{ borderWidth: 0 }} attached="top">
            Book a Visit
          </Header>

          <Segment clearing style={{ borderWidth: 0 }}>
            <FinalForm
              onSubmit={(values: IBookVisitFormData) =>
                BookMeeting(values).catch((error) => ({
                  [FORM_ERROR]: error,
                }))
              }
             validate={validate}
              render={({
                handleSubmit,
                submitting,
                submitError,
                invalid,
                pristine,
                dirtySinceLastSubmit,
              }) => (
                <Form onSubmit={handleSubmit} error loading={submitting}>
                  <Header style={{ borderWidth: 0 }} as="h3" attached="top">
                    Meeting Reference:
                  </Header>
                  <Checkbox
                    label="Auto Generate Meeting Reference. "
                    checked={badgeFlag}
                    onClick={changeBadgeFlag}
                  />

                  {!badgeFlag && (
                    <Form.Group widths="1">
                      <Field
                        label="Meeting Reference"
                        name="meetingRef"
                        placeholder="Meeting Reference"
                        component={TextInput}
                      />
                    </Form.Group>
                  )}
                  <Header style={{ borderWidth: 0 }} as="h3" attached="top">
                    Attendee Details:
                  </Header>
                  <Form.Group widths="equal">
                    <Field
                      label="First Name"
                      name="AttendeeFirstName"
                      placeholder="Attendee First Name"
                      component={TextInput}
                      required={true}
                    />
                    <Field
                      label="Last Name"
                      name="AttendeeLastName"
                      placeholder="Attendee Last Name"
                      component={TextInput}
                      required={true}
                    />

                    <Field
                      label=" Email"
                      name="AttendeeEmail"
                      placeholder="Attendee Email"
                      type="email"
                      component={TextInput}
                      required={true}
                    />

                    <Field
                      label=" Company"
                      name="AttendeeCompany"
                      placeholder="Attendee Company"
                      component={TextInput}
                      required={true}
                    />
                  </Form.Group>
                  <Header style={{ borderWidth: 0 }} as="h3" attached="top">
                    Host Details:
                  </Header>

                  <Form.Group widths="equal">
                    <Checkbox
                      label="I'm the host. "
                      checked={hostFlag}
                      onClick={changeHostFlag}
                    />
                  </Form.Group>
                  {!hostFlag && (
                    <Form.Group widths="equal">
                      <Field
                        label="Department"
                        name="department"
                        placeholder="Department"
                        component={TextInput}
                      />
                      <Field
                        label="Depot"
                        name="depot"
                        options={depots}
                        placeholder="Depot"
                        component={SelectInput}
                      />
                      <Field
                        readOnly={hostFlag}
                        label="Name"
                        name="hostName"
                        placeholder="Host Name"
                        component={TextInput}
                      />

                      {/* for testing the build */}

                      <Field
                        value={hostFlag ? user?.email : ""}
                        readOnly={hostFlag}
                        label="Email"
                        name="hostEmail"
                        placeholder="Host Email"
                        type="email"
                        component={TextInput}
                      />
                    </Form.Group>
                  )}
                  <Header style={{ borderWidth: 0 }} as="h3" attached="top">
                    Date/ Time
                  </Header>
                  <Form.Group widths="1">
                    <Field
                      name="date"
                      date={true}
                      placeholder="Date"
                      component={TimeInput}
                      required={true}
                    />
                    <Field
                      name="time"
                      time={true}
                      placeholder="Time"
                      component={TimeInput}
                      required={true}
                    />
                  </Form.Group>
                  {dateError.length > 0 && (
                    <p style={{ color: "red" }}>{dateError}</p>
                  )}
                  <Form.Group>
                    <Checkbox
                      label="Print Label "
                      checked={printLabelFlag}
                      onClick={ChangePrintLabelFlag}
                      toggle
                    />
                  </Form.Group>
                  <Button
                    icon="book"
                    loading={submitting}
                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                    positive
                    content="Book Visit"
                  />
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
      </ManageMeetingLayout>
  );
};

export default observer(BookMeeting);
