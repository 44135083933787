import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { Form as FinalForm, Field } from "react-final-form";
import {
  Grid,
  Segment,
  Form,
  Button,
  Header,
  Checkbox,
} from "semantic-ui-react";
import TextInput from "../../common/form/TextInput";
import { FORM_ERROR } from "final-form";
import { IBadge } from "../../models/badge";
//import SelectInput from "../../common/form/SelectInput";
import { observer } from "mobx-react-lite";
import ErrorMessage from "../layout/ErrorMessage";
import { IDepotsDetails } from "../../models/tenant";

interface IProps {
  tenantId?: string;
  tenantName?: string;
  depot?: IDepotsDetails
  depotId?: string
}
const AddBadge: React.FC<IProps> = ({ tenantId, tenantName, depot, depotId}) => {
  const rootStore = useContext(RootStoreContext);
  const {SetDepotId  } = rootStore.depotStore;
  const {
    GetBadgeNumber,
    AddBadge,
    nextFromBadgeNumber,
    singleBadgeFlag,
    badgeCheckboxChange,
  } = rootStore.badgeStore;

  useEffect(() => {
    SetDepotId(depot?.id!);
    GetBadgeNumber();
  }, [depot, SetDepotId, GetBadgeNumber]);

  return (
    <Grid centered>
      <Grid.Column width={15}>
        <Header as="h2" attached="top">
          Add Badge for: {tenantName}, {depot?.code}
        </Header>
        <Segment clearing>
          <FinalForm
            onSubmit={(values: IBadge) =>
              AddBadge(values).catch((error) => ({
                [FORM_ERROR]: error,
              }))
            }
            // validate={validate}
            render={({
              handleSubmit,
              submitting,
              submitError,
              invalid,
              pristine,
              dirtySinceLastSubmit,
            }) => (
              <Form onSubmit={handleSubmit} error>
             
                <Field
                  label="Depot/Location Code"
                  name="depotCode"
                  type="text"
                  readOnly={true}
                  defaultValue={depot?.code}
                  placeholder="Depot Code"
                  component={TextInput}
                />
              <Field
                  
                  name="depotId"
                  type="hidden"
                  readOnly={true}
                  defaultValue={depot?.id}
                 
                  component={TextInput}
                />


                <Checkbox
                  label="Do you want to add multiple Badges? "
                  checked={singleBadgeFlag}
                  onClick={badgeCheckboxChange}
                />
                {singleBadgeFlag && nextFromBadgeNumber.length > 0 ? (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>From </label>

                      {nextFromBadgeNumber === "0" ? (
                        <input
                          name="badgeNumFrom"
                          type="number"
                          placeholder="From"
                          value={nextFromBadgeNumber || ""}
                        />
                      ) : (
                        <input
                          name="badgeNumFrom"
                          type="number"
                          readOnly
                          value={nextFromBadgeNumber}
                          placeholder="From"
                        />
                      )}
                    </Form.Field>

                    <Field
                      label=" To"
                      name="badgeNumTo"
                      type="number"
                      placeholder="To"
                      component={TextInput}
                    />
                  </Form.Group>
                ) : (
                  <Field
                    label="Badge Number:"
                    name="badgeNum"
                    type="number"
                    placeholder="Badge number"
                    component={TextInput}
                  />
                )}
                <Field
                  name="tenantId"
                  type="hidden"
                  defaultValue={tenantId}
                  component={TextInput}
                />
                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage error={submitError} />
                )}
                <br />
                <Button
                  fluid
                  icon="plus"
                  loading={submitting}
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  positive
                  content="Add"
                />
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default observer(AddBadge);
