import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Modal } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/rootStore";

const RemoveTenantRep: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { repModal, repId, tenantId, closeRepModal } = rootStore.modalStore;
  const { RemoveRep } = rootStore.repStore;

  return (
    <Modal
      size="small"
      open={repModal}
      onClose={closeRepModal}
      style={{
        height: 124,
        left: "auto",
        right: "auto",
        marginTop: 90,
      }}
    >
      <Modal.Header as="h2" content="Remove Representative?" />
      <Modal.Actions>
        <Button
          color="green"
          content="Yes"
          onClick={() => RemoveRep(repId, tenantId)}
        />
        <Button color="red" content="No" onClick={closeRepModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default observer(RemoveTenantRep);
