import React, { useContext, useEffect } from "react";
import { Button, Grid, Image } from "semantic-ui-react";
import logo from "../../assets/visIT_logo.png";
import { history } from "../..";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import "../../style/visitor.css";

const VisitType = (props: any) => {
  const rootStore = useContext(RootStoreContext);

  const { depot, getDepot } = rootStore.depotStore;

  const { match } = props;
  let { depotCode, tenantName } = match.params;

  useEffect(() => {
    if (!depot) {
      getDepot(tenantName, depotCode);
    }
  }, [depotCode, tenantName, getDepot, depot]);

  // const BackHomeClick = () => {
  //     clearVisitorData();
  //     history.push(`/depot/${depot?.name}`);
  // };

  return (
    <div>
      <Button
        style={{ marginBottom: 15, marginLeft: 25, marginTop: 20 }}
        size="big"
        icon="home"
        // onClick={BackHomeClick}
        default
        content="Home"
      />
      <Grid centered style={{ marginTop: 5 }}>
        <Image
          src={logo}
          style={{
            marginTop: 20,
            marginBottom: 25,
          }}
        />

        <Grid.Row>
          <Grid.Column width={3}>
            <Button
              style={{
                marginTop: 25,
                backgroundColor: "#e4cf36",
                color: "#f3f3f3",
                height: 250,
              }}
              //  onClick={(e: React.MouseEvent<HTMLButtonElement>, d: any) => openVisitorCodeOptionModal(e, d)}
              fluid
              name="planned"
              size="massive"
              content="Planned Visit"
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Button
              style={{
                marginTop: 25,
                backgroundColor: "#447ca5",
                color: "#f3f3f3",
                height: 250,
              }}
              onClick={() => history.push(`/unplanned-visit/${tenantName}/${depotCode}`)}
              fluid
              content="Unplanned Visit"
              size="massive"
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Button
              style={{
                marginTop: 25,
                backgroundColor: "#4db25e",
                color: "#f3f3f3",
                height: 250,
              }}
              // onClick={() => history.push("/regular-visitor")}
              // onClick={(e: React.MouseEvent<HTMLButtonElement>, d: any) => openVisitorCodeOptionModal(e, d)}
              fluid
              name="regular"
              size="massive"
              content="Regular Visitor"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* <div className="BottomNam">
        <NavBar backgroundColor={"#be2332"} height={20} />
      </div> */}
    </div>
  );
};

export default observer(VisitType);
