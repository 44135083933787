import React, { useContext, useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { ExportCSV } from "../../../common/util/utils";
import { Grid, Header, Form, Button } from "semantic-ui-react";
import { FORM_ERROR } from "final-form";
import SelectInput from "../../../common/form/SelectInput";
import { RootStoreContext } from "../../../stores/rootStore";
import { IDailyVisits, IDateRangeVisitors } from "../../../models/reportModel";
import { observer } from "mobx-react-lite";
import DateInput from "../../../common/form/DateInput";
import VisitStatus from "../../layout/ReportLayout";

 
const PartnersReport: React.FC  = ( ) => {
  const rootStore = useContext(RootStoreContext);
  const [loading, setLoading] = useState(true)
  const {
    todaysPartnerReport,
    dateRangePartnerReport,
    partnerDailyExport,
    partnerDateRangeExport,
  } = rootStore.reportStore;
  const {depots,ListByUserDepot} = rootStore.depotStore
  useEffect(() => {
    ListByUserDepot().then(() => setLoading(false));
    
  }, [setLoading,ListByUserDepot]);
  return (
    <VisitStatus>
      <Grid columns={2} centered widths={"equal"}>
        <Grid.Row>
          <Grid.Column>
            <Header style={{ borderWidth: 0 }} as="h3" attached="top">
              Partners
            </Header>

            <FinalForm
              onSubmit={(values: IDailyVisits) => {
                todaysPartnerReport(values).catch((error) => ({
                  [FORM_ERROR]: error,
                }));
              }}
              //   validate={validate}
              render={({
                handleSubmit,
                submitting,
                submitError,
                invalid,
                pristine,
                dirtySinceLastSubmit,
              }) => (
                <Form onSubmit={handleSubmit} error>
                  <Field
                    label="Depot"
                    name="depot"
                    placeholder="Depot"
                    options={depots}
                    loading={loading}
                    component={SelectInput}
                  />
                  <br />
                  <br />

                  <div className="searchBtn">
                    <Button
                      fluid
                      icon="search"
                      loading={submitting}
                      disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                      primary
                      content="Search"
                    />
                  </div>
                </Form>
              )}
            />
            <br />
            <Button
              fluid
              content="Export"
              disabled={partnerDailyExport.length === 0}
              icon="file excel outline"
              onClick={() =>
                ExportCSV(partnerDailyExport, "PartnerDailyReport")
              }
            />
          </Grid.Column>

          <Grid.Column>
            <Header style={{ borderWidth: 0 }} as="h3" attached="top">
              Partners by Date Range
            </Header>
            <FinalForm
              onSubmit={(values: IDateRangeVisitors) => {
                dateRangePartnerReport(values).catch((error) => ({
                  [FORM_ERROR]: error,
                }));
              }}
              //   validate={validate}
              render={({
                handleSubmit,
                submitting,
                submitError,
                invalid,
                pristine,
                dirtySinceLastSubmit,
              }) => (
                <Form onSubmit={handleSubmit} error>
                  <Form.Group widths="equal">
                    <Field
                      loading={loading}
                      label="Depot"
                      name="depot"
                      placeholder="Depot"
                      options={depots}
                      component={SelectInput}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Field
                      name="dateFrom"
                      date={true}
                      placeholder="From"
                      component={DateInput}
                    />

                    <Field
                      name="dateTo"
                      placeholder="To"
                      date={true}
                      component={DateInput}
                    />
                  </Form.Group>
                  <Button
                    fluid
                    icon="search"
                    loading={submitting}
                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                    primary
                    content="Search"
                  />
                </Form>
              )}
            />
            <br />
            <Button
              content="Export"
              disabled={partnerDateRangeExport.length === 0}
              icon="file excel outline"
              fluid
              onClick={() =>ExportCSV(partnerDateRangeExport, "PartnerDateRangeReport")}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </VisitStatus>
  );
};
export default observer(PartnersReport);
