import React, { useContext } from "react";
import TextInput from "../../common/form/TextInput";
import { FORM_ERROR } from "final-form";
import { Grid, Segment, Form, Button } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { IResetPassword } from "../../models/resetPasswordModel";
import { RootStoreContext } from "../../stores/rootStore";
import { combineValidators, isRequired, matchesField } from "revalidate";
import ResetPasswordErrorMessage from "../layout/resetPasswordErrorMessage";
import { observer } from "mobx-react-lite";
import AnonymousLayout from "../layout/AnonymousLayout";

const validate = combineValidators({
  password: isRequired("password"),
  password_confirmation: matchesField(
    "password",
    "password_confirmation"
  )({ message: "Passwords do not match" }),
});

const cleanToken = (block_1: string, block_2: string, block_3: string) => {
  let localTokenString = block_1 + block_2 + block_3;
  localTokenString = localTokenString.replace(/\PLUS/g, "+");
  localTokenString = localTokenString.replace(/\SLASH/g, "/");
  return localTokenString;
};

function ResetPassword(props: any) {
  const { match } = props;
  const rootStore = useContext(RootStoreContext);
  const { ResetPassword } = rootStore.userStore;
  let { passToken_block1 } = match.params;
  let { passToken_block2 } = match.params;
  let { passToken_block3 } = match.params;
  let { email } = match.params;
  let { username } = match.params;

  return (
    <AnonymousLayout>
      <Grid.Column width="6">
        <Segment clearing>
          <h2 className="lead">Please set your password!</h2>
          <FinalForm
            onSubmit={(values: IResetPassword) =>
              ResetPassword(values).catch((error) => ({
                [FORM_ERROR]: error,
              }))
            }
            validate={validate}
            render={({
              handleSubmit,
              submitting,
              submitError,
              invalid,
              pristine,
              dirtySinceLastSubmit,
            }) => (
              <Form onSubmit={handleSubmit} error>
                <Field
                  name="email"
                  type="hidden"
                  value={email}
                  initialValue={email}
                  component={"input"}
                />

                <Field
                  name="username"
                  type="text"
                  readOnly={true}
                  value={username}
                  initialValue={username}
                  component={"input"}
                />
                <Field
                  name="password"
                  type="password"
                  placeholder="Password"
                  component={TextInput}
                />
                <Field
                  name="password_confirmation"
                  type="password"
                  placeholder="Repeat Password"
                  component={TextInput}
                />
                <Field
                  name="token"
                  type="hidden"
                  initialValue={cleanToken(
                    passToken_block1,
                    passToken_block2,
                    passToken_block3
                  )}
                  defaultValue={cleanToken(
                    passToken_block1,
                    passToken_block2,
                    passToken_block3
                  )}
                  component={TextInput}
                />

                <br></br>
                <Button
                  fluid
                  icon="key"
                  loading={submitting}
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  primary
                  content="Submit"
                />
                {submitError && !dirtySinceLastSubmit && (
                  <ResetPasswordErrorMessage error={submitError} />
                )}
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>
    </AnonymousLayout>
  );
}
export default observer(ResetPassword);
