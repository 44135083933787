import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

interface IProps {
  open: boolean;
  hasReference: (name: string) => void;
}
const VisitorCodeOption: React.FC<IProps> = ({ open, hasReference }) => {
  return (
    <Modal className="code-option-modal" open={open} size="small">
      <Modal.Header>
        Do you have a Meeting Reference number. <br />
        This may have been emailed to you?
      </Modal.Header>
      <Modal.Actions>
        <Button positive onClick={() => hasReference("yes")}>
          Yes
        </Button>
        <Button color="red" onClick={() => hasReference("no")}>
          No
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default observer(VisitorCodeOption);
