
import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { IUser } from "../../../models/userModel";
interface IProps {
  activeItem: string;
  user: IUser;
}
const TenantAdminMenu: React.FC<IProps> = ({ activeItem, user }) => {
  return (
    <Menu vertical fluid>
      <Menu.Item
        content="Manage Visits"
        active={
          activeItem === activeItem ||
          activeItem.includes("visit") ||
          activeItem.includes("register-partner")
        }
        as={Link}
        to="/dashboard"
      />
      <Menu.Item
          content='Register Partner'
          name="/register-partner"
          active={activeItem === "/register-partner"}
          as={Link}
          to="/register-partner"
        />
      <Menu.Item
        content="Manage Tenant"
        active={activeItem.includes("/tenant-details/")}
        as={Link}
        to={`/tenant-details/${user?.tenantId}`}
      />
      <Menu.Item
        content="Reports"
        active={activeItem.includes("report")}
        as={Link}
        to="/reports"
      />
    </Menu>
  );
};

export default TenantAdminMenu;
