import React, { useContext, useEffect, useState } from "react";
import ManageTenantLayout from "../../layout/ManageTenantLayout";
import { Field, Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
 
import { RootStoreContext } from "../../../stores/rootStore";
import SelectInput from "../../../common/form/SelectInput";
import { ITenantRequest } from "../../../models/tenant";
import { Button, Form } from "semantic-ui-react";

const UpdateTenant: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const [loading, setLoading] = useState(true);
  const { findTenant, shortListTenant, listTenants_shortlist } = rootStore.tenantStore;

  useEffect(() => {
   shortListTenant().then(() => setLoading(false));
  }, [shortListTenant]);

  return (
    <ManageTenantLayout>
      <FinalForm
        onSubmit={(value: ITenantRequest) => {
          findTenant(value).catch((error) => ({
            [FORM_ERROR]: error,
          }));
        }}
        // validate={validate}
        render={({
          handleSubmit,
          submitting,
          submitError,
          invalid,
          pristine,
          dirtySinceLastSubmit,
        }) => (
          <Form onSubmit={handleSubmit} error>
            <Field
              label="Tenant"
              name="tenantId"
              placeholder="Tenant"
              options={listTenants_shortlist}
              loading={loading}
              component={SelectInput}
            />
            <br />
            <br />

            <div className="searchBtn">
              <Button
                fluid
                icon="search"
                loading={submitting}
                disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                primary
                content="Search"
              />
            </div>
          </Form>
        )}
      />
    </ManageTenantLayout>
  );
};

export default UpdateTenant;
