import { RootStore } from "./rootStore";
import { observable, action } from "mobx";

export default class ModalStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable modal = {
    open: false,
    body: null,
  };

  @observable partnerModal = false;
  @observable partnerId = ''


  @observable repModal = false;
  @observable repId = ''
  @observable tenantId = ''

 @action openRepModal = (repId: string, tenantId: string) => {
    this.repId = repId
    this.tenantId = tenantId
    this.repModal = !this.repModal;
  };


  @action closeRepModal = () => this.repModal = false


  @action openPartnerModal = (partnerId: string) => {
    this.partnerId = partnerId
    this.partnerModal = !this.partnerModal;
  };


  @action closePartnerModal = () => this.partnerModal = false


  @action openModal = (content: any) => {
    this.modal.open = true;
    this.modal.body = content;
  };
  @action closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
  };
}
