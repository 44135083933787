import React, { Fragment, useState, useEffect } from "react";
import { Grid, Header, Message } from "semantic-ui-react";
import PhotoWidgetDropZone from "./fileWidgetDropzone";

interface IProps {
  fileUploaded: boolean;
}
const UploadFile: React.FC<IProps> = ({ fileUploaded = false }) => {
  const headerStyle = {
    textAlign: "center",
    paddingBottom: 10,
    textWeight: "strong",
  };

  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    //  if(fileUploaded)
    //  setFiles([])
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [setFiles, fileUploaded, files]);
  return (
    <Fragment>
      <Grid>
        <Grid.Column width={7}>
          <Header
            style={headerStyle}
            color="teal"
            sub
            content="Select/ Drop a File"
          />
          <PhotoWidgetDropZone setFiles={setFiles} />
          <br />
          <strong>File Name: </strong>{" "}
          {files.length > 0 && (
            <div>
              <p>{files[0].name}</p>{" "}
            </div>
          )}
        </Grid.Column>
        <Grid.Column width={9}>
          <Header
            style={headerStyle}
            color="teal"
            sub
            content="Download File Template"
          />

          {/* <Message
            warning
            header="File Format"
            content="Please download the file template and follow its format or else this functionality might not be completed successfully."
          /> */}
          <Message warning>
            <Message.Header>File Format!</Message.Header>
            <Message.Item>
              Please download the file template and follow its format or else
              this functionality might not be completed successfully.
            </Message.Item>
            <Message.Item>
              Please note that has per the business policy, workID's MUST be
              unique in the system.
            </Message.Item>
            <Message.Item>
              Please note that the file cannot have repeated data..
            </Message.Item>
          </Message>
          <a
            style={{ textDecoration: "none" }}
            href="http://visit.masterlink.ie/template/visit-partner-upload-templateV1.0.xlsx"
            target="_black"
          >
            File Template
          </a>
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default UploadFile;
