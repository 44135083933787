import React, { useContext } from "react";
import {
  Table,
  Button,
  Modal,

} from "semantic-ui-react";
 
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "./../../stores/rootStore";

interface IProps {
  open?: boolean;
}
const PartnerHouseRules: React.FC<IProps> = ({open}) => {
  const rootStore = useContext(RootStoreContext);
  const {partnerAcceptHouseRules} = rootStore.partnerStore
  return (
    <Modal className="partner-house-rules-modal" open={open} size="small">
      <Modal.Header>House Rules</Modal.Header>
      <Modal.Content>
        <Table striped>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                When in the Depot - you will always wear a Protective Personal
                Equipment.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                You should not operate any PPE equipment, unless you are trained
                to do so.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                You will adhere to all the food hygiene, Covid-19 and security
                protocols that Masterlink have in place.
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>
                Use of your personal mobile phone is not allowed in the Depot.
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>
                Personal belongings (such as bag) must be left in the Locker
                Room or Canteen.
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>
                In the last 14 days you have not had any symptoms of Covid-19
                (such as, Fiver, High Temperature, Sore Throat, Runny Nose,
                Breathless, etc)?
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                In the last 14 you have not travelled no any Country that is not
                on the Government Travel Green List.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                I understand that there is a Search Policy in place and I agree
                to accept same.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <div className="modal-footer">
          <div className="action-text">
            <p>Your Health & Safety is important to us!</p>
          </div>

          <Button positive onClick={partnerAcceptHouseRules} content="Accept" />
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default observer(PartnerHouseRules);
