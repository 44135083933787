import { RootStore } from "./rootStore";
import { observable, action, reaction, computed } from "mobx";
import { IUser } from "../models/userModel";

export default class CommonStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    window.localStorage.setItem("jwt", token);
                } else {
                    window.localStorage.removeItem("jwt");
                }
            }
        );
    }

    @observable token: string | null = window.localStorage.getItem("jwt");
    @observable appLoaded = false;

    @action setToken = (token: string | null) => {
        this.token = token;
    };

    @action setAppLoaded = () => {
        this.appLoaded = true;
    };

    @observable user: IUser | null = null;

    @computed get isLoggedIn() {
        return !!this.user;
    }
}