import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Modal } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/rootStore";


 
const RemovePartnerModal: React.FC = ( ) => {
  const rootStore = useContext(RootStoreContext);
  const { partnerModal,partnerId,closePartnerModal } = rootStore.modalStore;
  const { removePartner } = rootStore.partnerStore;


  return (
    <Modal size="small" open={partnerModal} onClose={closePartnerModal}   style={{
      height: 124,
      left: "auto",
      right: "auto",
      marginTop: 90,
  }}>
      <Modal.Header  as='h2'content="Remove Partner?" />
      <Modal.Actions>
        <Button
          color="green"
          content="Yes"
          onClick={() => removePartner(partnerId)}
        />
        <Button color="red" content="No" onClick={closePartnerModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default observer(RemovePartnerModal);
