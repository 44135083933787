import React from "react";
import { Image, Button, Grid } from "semantic-ui-react";
import { Container } from "reactstrap";
import AdminNavBar from "./layout/AdminNavBar";
import image404 from "../assets/404.jpg";
import { history } from "..";
const PageNotfound = () => {
  return (
    <div>
      <AdminNavBar />
      <Grid centered>
        <Container>
          <Image src={image404} size="big" verticalAlign="middle" />
          <br />
          <Button
            content="Back"
            icon="left arrow"
            onClick={() => history.goBack()}
            primary
            labelPosition="left"
          />
        </Container>
      </Grid>
    </div>
  );
};

export default PageNotfound;
