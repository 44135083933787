import React from "react";
import { IVisitAttendance } from "../../../../models/reportModel";
import { Button, Card, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import Moment from "react-moment";
import agents from "../../../../API/agents";

interface IProps {
  attendee: IVisitAttendance;
  depotId: string
}
const PlannedVisitorCard: React.FC<IProps> = ({ attendee, depotId }) => {
// const rootStore = useContext(RootStoreContext);
// const { ManualCheckOut } = rootStore.visitorStore;
// const { cancelMeeting } = rootStore.reportStore;

  return (
    <div>
      <Segment style={{ borderWidth: 0 }}>

        <Card fluid>
        <Card.Content>
            <Card.Header>
              {attendee.attendee.firstName + " "}
              {attendee.attendee.lastName}
            </Card.Header>
            <Card.Content>
              <strong>Meeting Reference: </strong>
              {attendee.visitReference}
            </Card.Content>
            <Card.Content>
              <strong>Host: </strong>
              {attendee.host.name}
            </Card.Content>

            <Card.Content>
              <strong>Company: </strong>
              {attendee.attendee.company}
            </Card.Content>


            {!attendee.checkedIn ? (
              <strong>Visitor Has Not checked In Yet!</strong>
            ) : (
              <Card.Content>
                CheckIn Time:
                <Moment format="YYYY/MM/DD @ HH:mm">
                  {attendee.checkInTime}
                </Moment>
              </Card.Content>
            )}
            {attendee.checkedIn && attendee.hasCheckedOut ? (
              <div>
                <Card.Content>
                  Expected Time:
                  <Moment format="YYYY/MM/DD @ HH:mm">
                    {attendee.dateOfVisit}
                  </Moment>
                </Card.Content>
                <div className="ui two buttons">
                  <br />
                  <a
                    href={`api/view-visitor-questionnaire/${attendee.visit.depot.code}/${attendee.id}`}
                    style={{ width: "100%" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      basic
                      primary
                      icon="eye"
                      fluid
                      content="View Questionnaire"
                    />
                  </a>
                </div>
              </div>
            ) : (
              !attendee.hasCheckedOut &&
              attendee.checkedIn && (
                <div className="ui two buttons">
                  <br />
                  <Button
                    basic
                    color="red"
                    onClick={() =>{}
                     // ManualCheckOut(depotId, attendee.id, false)
                    }
                    fluid
                    content="Manual Checkout"
                  />
                  <a
                    href={`api/view-visitor-questionnaire/${attendee.visit.depot.code}/${attendee.id}`}
                    style={{ width: "100%" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      basic
                      primary
                      icon="eye"
                      fluid
                      content="View Questionnaire"
                    />
                  </a>
                </div>
              )
            )}

            {!attendee.checkedIn && (
              <div>
                <Card.Content>
                Expected Time:
                  <Moment format="YYYY/MM/DD @ HH:mm">
                    {attendee.dateOfVisit}
                  </Moment>
                </Card.Content>
                <div className="ui two buttons">
                  <br />
                  <Button
                    basic
                    color="red"
                    icon="eye"
                    fluid
                     onClick={() => agents.visitor.cancelVisit(attendee.visitReference)}
                    content="Cancel Visit"
                  />
                  
                </div>
              </div>
            )}
          </Card.Content>
        </Card>
      
      
      </Segment>
    </div>
  );
};

export default observer(PlannedVisitorCard);
