import React, { useContext } from "react";
import {
    Modal,
    Table,
    Checkbox,
    Button,
    CheckboxProps,
    Form,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";

interface IProps {
    open: boolean;
    accept: (name: string) => void;
    denny: (name: string) => void;
}

const OtherRules: React.FC<IProps> = ({ open, accept, denny }) => {
    const rootStore = useContext(RootStoreContext);
    const {
        or_1,
        or_2,
        or_proceedBtn, CloseModalOR,
        onchangeOtherRules,
    } = rootStore.visitorStore;

    return (
        <Modal
            style={{ height: 309, width: 960, left: "auto", right: "auto", marginTop: 40, fontSize: 16 }}
            // style={{ height: 296, left: "auto", right: "auto", marginTop: 40 }}
            open={open}
            closeOnDimmerClick
            onClose={CloseModalOR}
            size="small"
        >
            <Modal.Header>Other Rules</Modal.Header>
            <Modal.Content>
                <Table striped>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                I agreed to be contacted by Masterlink by email after this visit.
              </Table.Cell>
                            <Table.Cell>
                                <Form.Group>
                                    <Checkbox
                                        label="Accept"
                                        value="yes"
                                        name="or_1"
                                        checked={or_1 === "yes"}
                                        onChange={(
                                            event: React.FormEvent<HTMLInputElement>,
                                            data: CheckboxProps
                                        ) => onchangeOtherRules(event, data)}
                                    />

                                </Form.Group>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                I accept that Masterlink will retain details of my visit and may
                                share them with others after my visit.
              </Table.Cell>
                            <Table.Cell>
                                <Form.Group>
                                    <Checkbox
                                        label="Accept"
                                        value="yes"
                                        name="or_2"
                                        checked={or_2 === "yes"}
                                        onChange={(
                                            event: React.FormEvent<HTMLInputElement>,
                                            data: CheckboxProps
                                        ) => onchangeOtherRules(event, data)}
                                    />

                                </Form.Group>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <div className="modalPageNum">
                    <p> Page 4 of 4</p>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={!or_proceedBtn}
                    positive
                    onClick={() => accept("OtherRules")}
                >
                    Proceed
        </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default observer(OtherRules);