import React from "react";
import NavBar from "../layout/NavBar";
import { Button, Grid, Image } from "semantic-ui-react";
import logo from "../../assets/visIT_logo.png";
import { observer } from "mobx-react-lite";
import { history } from "../..";
import AnonymousLayout from "../layout/AnonymousLayout";

const DepotLogin = () => {
  return (
    <AnonymousLayout>
      <Grid.Row centered>
        <Button
          content="Admin Login"
          color="green"
          size="massive"
          onClick={() => history.push("/ml-admin")}
        />
      </Grid.Row>
    </AnonymousLayout>
  );
};

export default observer(DepotLogin);
