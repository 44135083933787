
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Segment, Table } from "semantic-ui-react";
import { ITenantResult } from "../../../models/tenant";
import { RootStoreContext } from "../../../stores/rootStore";
import ManageTenantLayout from "../../layout/ManageTenantLayout";

const ViewTenants = () => {
  const rootStore = useContext(RootStoreContext);

  const {  listTenant, tenantList } = rootStore.tenantStore;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    listTenant().then(() => setLoading(false));
  }, [listTenant]);

  return (
    <ManageTenantLayout>
      <Segment loading={loading}>
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell># of Depot/ Locations</Table.HeaderCell>
              <Table.HeaderCell>Date Registered</Table.HeaderCell>
              <Table.HeaderCell># Reps</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tenantList &&
              tenantList.map((e: ITenantResult, i: number) => {
                return (
                  <Table.Row key={e.id}>
                    <Table.Cell>{e.name}</Table.Cell>
                    <Table.Cell>{e.numDepots}</Table.Cell>
                    <Table.Cell >
                      {e.dateRegistered.toString().split("T")[0]}
                    </Table.Cell>
                    <Table.Cell>{e.numReps}</Table.Cell>
                    <Table.Cell>
                      <Button
                        content="View"
                        icon="eye"
                        labelPosition="left"
                        primary
                        as={Link}
                        to={`/tenant-details/${e.id}`}
                      />

                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Segment>
    </ManageTenantLayout>
  );
};

export default ViewTenants;
