import React, { useContext } from "react";
import { Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
interface IProps {
    open: boolean;
}
const CheckoutMessage: React.FC<IProps> = ({ open }) => {
    const rootStore = useContext(RootStoreContext);
    const { visitorName } = rootStore.visitorStore;

    return (
        <div className="checkoutMessage">
            <Modal
                dimmer="inverted"
                style={{
                    height: 305,
                    width: 600,
                    left: "auto",
                    right: "auto",
                    marginTop: 40,
                }}
                open={open}
            >
                <Modal.Header>Checkout</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <h1>Hi {visitorName}. You have Checked Out Successfully.</h1>
                        <h2>Thank you for Visiting Masterlink. We hope you have enjoyed your
              visit.</h2>
                        <hr />
                        <p style={{ color: "red", fontSize: 22, fontStyle: "italic" }}>
                            Please Return your Badge!
            </p>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default observer(CheckoutMessage);