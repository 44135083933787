import { observable, action, computed, runInAction } from "mobx";
import agent from "../API/agents";
import {
  IAddress,
  IDepot,
  IDepotFormValues,
  IDepotRequest,
} from "../models/depot";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { IDepotsDetails } from "../models/tenant";
import agents from "../API/agents";



export default class DepotStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable depots: IDepot[] = [];
  @observable depot: IDepotRequest | null = null;
  @observable depotName: string = "";
  @observable depotPassword: string = "";
  @observable userDepot: IDepotsDetails | null = null;
  @observable depotLocation: string = "";
  @observable tenantId: string = "";
  @observable depotId: string = "";


  @computed get isDepotLoggedIn() {
    return !!this.depot;
  }
 

  @action setTenantId = async (value: string) => {
    this.tenantId = value;
  };
  @action resetDepotList = async () => {
    this.depots = [];
  };
  @action SetDepotId = async (depotId: string) => {
    this.depotId = depotId;
  };
  @action addRowToTenantDepotsList = async () => {
    // const address: IAddress = {line1:'', line2:'', eirCode:'', county:''}
    // const depotObj:IDepotsDetails  = {id: uuidv4(), code:'',address }
    // const list = this.rootStore.tenantStore.tenant?.depots.push(depotObj)
  };
  @action setLocationReactOnlyFalse = async (data: IDepotsDetails) => {
    const location = this.rootStore.tenantStore.tenant?.depots.find(
      (e) => e.id === data.id
    )!;
    location.readonly = false;
  };
  @action AddDepot = async (values: IDepotFormValues) => {
    try {
      const depot = await agent.depot.create(values);
      if (depot) {
        this.rootStore.tenantStore.findTenant({
          tenantId: values.tenantId!,
        });
        this.rootStore.modalStore.closeModal();
      }
    } catch (error) {
      throw error;
    }
  };
  @action updateDepot = async (data: IDepotsDetails) => {
    try {
      const result = agents.depot.edit(data.id, data);

      if (result) {
        const location = this.rootStore.tenantStore.tenant?.depots.find(
          (e) => e.id === data.id
        )!;
        location.readonly = true;
        toast.success("Location updated successfully!");
      }
    } catch (error) {
      throw error;
    }
  };
  @action onChangeSwitch = async (field: string, value: string) => {
    switch (field) {
      case "depotName":
        this.depotName = value;
        break;
      case "depotPassword":
        this.depotPassword = value;
    }
  };
  @action ListByUserDepot = async () => {
    const user = this.rootStore.userStore.user;  
    switch (user?.role) {
      case "SuperAdmin":
        this.ListDepot(user.tenantId);
        break;
      case "TenantAdmin":
        this.ListDepot(user.tenantId);
        break;
      case "DepotManager":
     this.findUserDepot(user.depot)
        break;
      default:
        break;
    }
  };
  @action findUserDepot = async (userDepot: IDepotsDetails) => {
    try {
      this.depots[0] = {
        id: userDepot.id,
        key: userDepot.id,
        value: userDepot.id,
        text: `${userDepot.code} - ${userDepot.address.line1}`,
      };
    } catch (error) {}
  };
  @action ListDepot = async (tenantId: string) => {
    try {
      const depots = await agent.depot.list(tenantId);
      if(depots)
      this.depots = depots
    } catch (error) {
      throw error;
    }
  };
  getDepotLocation = (address: IAddress) => {
    runInAction(() => {
      this.depotLocation =
        address.line1 +
        ", " +
        address.line2 +
        ", " +
        address.county +
        ", " +
        address.eirCode;
    });
  };

    @action getDepot = async (tenantName: string, depotCode: string) => {
    try {
      const depot = await agent.depot.find(tenantName, depotCode);
      if (depot) {
        this.depot = depot;
      }
    } catch (error) {
      throw error;
    }
  };

}

  // // a tenant admin has access to all depots for his tenant
  // @action ListDepotByTenantId = async (tenantId: string) => {
  //   try {
  //     const isLoggedIn = this.rootStore.userStore;
  //     if (isLoggedIn.user?.role === "SuperAdmin") {
  //       // if (tenantId?.length === 0) tid = this.tenantId;
  //       // else tid = tenantId!;
  //       const depots = await agent.depot.list(tenantId);
  //       this.depots = depots;
  //     } else {
  //       this.depots[0] = {
  //         id: isLoggedIn.user?.depot.id!,
  //         key: isLoggedIn.user?.depot.code!,
  //         value: isLoggedIn.user?.depot.code!,
  //         text: isLoggedIn.user?.depot.code!,
  //       };
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  // @action ListDepotsForTenant = async (tenantId: string) => {
  //   try {
  //     const isLoggedIn = this.rootStore.userStore;

  //     if (isLoggedIn.user?.role === "SuperAdmin") {
 
  //       const depots = await agent.depot.list(tenantId);

  //       this.depots = depots;
  //     } else {
  //       this.depots[0] = {
  //         id: isLoggedIn.user?.depot.id!,
  //         key: isLoggedIn.user?.depot.code!,
  //         value: isLoggedIn.user?.depot.code!,
  //         text: isLoggedIn.user?.depot.code!,
  //       };
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  //};




 
