import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Image } from "semantic-ui-react";
import logo from "../../assets/visIT_logo.png";
import { history } from "../..";
import { RootStoreContext } from "../../stores/rootStore";
import Clock from "react-live-clock";
import { observer } from "mobx-react-lite";
import VisitorCodeOption from "../Modals/VisitorCodeOption";
import VisitorCodeOptionRegularVisitor from "../Modals/VisitorCodeOptionRegularVisitor";
import PartnerClockInModal from "../Modals/PartnerClockInModal";
import PartnerClockOutModal from "../Modals/PartnerClockOutModal";

const VisitorPortal = (props: any) => {
  const rootStore = useContext(RootStoreContext);
 // const [company, setCompany] = useState("");
  const [depotNameFlag, setDepotNameFlag] = useState(false);
  const [depotArr] = useState([
    "l2",
    "L2",
    "BT1",
    "BT5",
    "bt1",
    "bt5",
  ]);
  const { depot, getDepot } = rootStore.depotStore;

  const {
    openVisitorCodeOptionModal,
    openVisitorCodeOption,
    openVisitorCodeOptionRegular,
    hasReference,
    hasReferenceRegular,
  } = rootStore.visitorStore;
  
  const { match } = props;
  let {tenantName, depotCode } = match.params;

  useEffect(() => {
    if (depotCode) {
      getDepot(tenantName,depotCode);
    }

    if (depotArr.includes(depotCode)) 
    setDepotNameFlag(true);

 
  }, [depotCode, getDepot, depotArr,tenantName, setDepotNameFlag]);

  return (
    <div>
      <VisitorCodeOption
        hasReference={hasReference}
        open={openVisitorCodeOption}
      />

      <PartnerClockInModal />
      <PartnerClockOutModal />
      <VisitorCodeOptionRegularVisitor
        hasReferenceRegular={hasReferenceRegular}
        open={openVisitorCodeOptionRegular}
      />

      <Grid columns={2} centered style={{ marginTop: 5 }}>
        <Grid.Row>
          <Grid.Column>
            <Image
              centered
              src={logo}
              style={{
                marginTop: 20,
                marginBottom: 25,
              }}
            />
            <br />
          </Grid.Column>
        </Grid.Row>

        <div className="backgroundPhoto">
          <Grid.Row>
            <div style={{ textAlign: "left" }}>
              <h1>
                Welcome to {depot?.tenantName}{' '}
                {depotNameFlag && depot?.code.toUpperCase()}
              </h1>
              <h2>{depot?.address.line1}</h2>
              <h2>{depot?.address.line2}</h2>
              <h2>
                {depot?.address.county}, {depot?.address.eirCode}
              </h2>
            </div>
            <Grid.Column
              width={2}
              className="calendar"
              style={{
                textAlign: "center",
                marginLeft: 100,
              }}
            >
              <div>
                <h4>
                  <Clock date={Date.now()} format={"dddd"} />
                </h4>
                <h1>
                  <Clock date={Date.now()} format={"Do"} />
                </h1>
                <h2>
                  <Clock date={Date.now()} format={"MMMM"} />
                </h2>
              </div>
            </Grid.Column>
          </Grid.Row>
        </div>
      </Grid>
      <Grid centered>
        <Grid.Row columns="3">
          <Grid.Column width="3">
            <Button
              className="planned-visit-btn"
              onClick={(e: React.MouseEvent<HTMLButtonElement>, d: any) =>
                openVisitorCodeOptionModal(e, d)
              }
              name="planned"
              fluid
              size="massive"
            >
              Planned <br /> Visitor
            </Button>
          </Grid.Column>
          <Grid.Column width="3">
            <Button
              className="unplanned-visit-btn"
              onClick={() => history.push(`/unplanned-visit/${tenantName}/${depotCode}`)}
              fluid
              size="massive"
            >
              Unplanned <br /> Visitor
            </Button>
          </Grid.Column>
          <Grid.Column width="3">
            <Button
              className="regular-visitor-btn"
              onClick={(e: React.MouseEvent<HTMLButtonElement>, d: any) =>
                openVisitorCodeOptionModal(e, d)
              }
              fluid
              name="regular"
              size="massive"
            >
              Regular <br />
              Visitor
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="1">
          <Grid.Column width="6">
            <Button
              className="checkout-btn"
              onClick={() => history.push(`/checkout/${tenantName}/${depotCode}`)}
              fluid
              size="massive"
              content="Visitor Check-Out"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="1">
          <Grid.Column width="6">
            <Button
              className="partner-btn"
              onClick={() => history.push(`/partner/${tenantName}/${depotCode}`)}
              fluid
              size="massive"
              content="Partner Check In/Out"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default observer(VisitorPortal);
