import { FORM_ERROR } from "final-form";
import React, { useContext, useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { combineValidators, isRequired, matchesField } from "revalidate";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { IRegisterModel } from "../../models/userModel";
import TextInput from "../../common/form/TextInput";
import SelectInput from "../../common/form/SelectInput";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import ErrorMessage from "../layout/ErrorMessage";

// //http://revalidate.jeremyfairbank.com/common-validators/matchesField.html
const validate = combineValidators({
  name: isRequired("Name"),
  username: isRequired("Username"),
  email: isRequired("Email"),
  depotString: isRequired("depotString"),
  role: isRequired("Role"),
  email_confirmation: matchesField(
    "email",
    "email_confirmation"
  )({ message: "Emails do not match" }),
  // department: isRequired("Department")
});

interface IProps {
  tenantId?: string;
  tenantName?: string;
}

const Register: React.FC<IProps> = ({ tenantName, tenantId }) => {
  const rootStore = useContext(RootStoreContext);
  const { Register, loadUserRole, userRoles, user } = rootStore.userStore;
  const { ListDepot, depots } = rootStore.depotStore;
  const { closeModal } = rootStore.modalStore;
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loadingDepots, setLoadingDepots] = useState(true);
  const [roleArr] = useState(["TenantAdmin", "SuperAdmin"]);
  useEffect(() => {
    ListDepot(tenantId!).then(() => setLoadingDepots(false));
    loadUserRole().then(() => setLoadingRoles(false));
  }, [ListDepot, loadUserRole,tenantId]);

  const CloseRegisterModal = () => {
    closeModal();
    return <Redirect to="/dashboard" />;
  };

  if (!roleArr.includes(user?.role!)) return <CloseRegisterModal />;

  return (
    <Grid centered>
      <Grid.Column width={15}>
        <Header as="h2" attached="top">
          Register a New User for: <u>{tenantName}</u>
        </Header>
        <Segment clearing>
          <FinalForm
            onSubmit={(values: IRegisterModel) =>
              Register(values).catch((error) => ({
                [FORM_ERROR]: error,
              }))
            }
            validate={validate}
            render={({
              handleSubmit,
              submitting,
              submitError,
              invalid,
              pristine,
              dirtySinceLastSubmit,
            }) => (
              <Form onSubmit={handleSubmit} error>
                <Form.Group widths="equal">
                  <Field
                    label="Location"
                    name="depotString"
                    options={depots}
                    placeholder="Select a Location"
                    loading={loadingDepots}
                    component={SelectInput}
                  />
                  <Field
                    loading={loadingRoles}
                    label="Role"
                    name="role"
                    placeholder="Role"
                    options={userRoles}
                    component={SelectInput}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Field
                    label="Name"
                    name="name"
                    placeholder="Name"
                    component={TextInput}
                  />
                  <Field
                    label="Username"
                    name="username"
                    placeholder="Username"
                    component={TextInput}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  {/* <Field
                    label="Department"
                    name="department"
                    placeholder="Department"
                    component={TextInput}
                  /> */}

                  <Field
                    label="Email"
                    name="email"
                    placeholder="Email"
                    type="email"
                    component={TextInput}
                  />
                  <Field
                    label="Repeat Email"
                    name="email_confirmation"
                    placeholder="Confirm Email"
                    type="email"
                    component={TextInput}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Field
                    name="password"
                    type="hidden"
                    initialValue={"M@ster_pa$$14"}
                    defaultValue={"M@ster_pa$$14"}
                    component={TextInput}
                  />
                  <Field
                    name="tenantId"
                    defaultValue={tenantId}
                    type="hidden"
                    component={TextInput}
                  />
                </Form.Group>

                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage error={submitError} />
                )}
                <br />
                <Button
                  fluid
                  icon="signup"
                  loading={submitting}
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  positive
                  content="Register"
                />
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default observer(Register);
