import React, { useEffect, useState } from "react";
import { Menu } from "semantic-ui-react";
import Dashboard from "./Dashboard";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
 
interface IProps {
  children: any;
}
export const ManageMeetingLayout: React.FC<IProps>  = ({ children }) => {
  const [activeItem, setActiveItem] = useState("/dashboard");

  useEffect(() => {
    setActiveItem(window.location.pathname);
  }, [setActiveItem]);

  return (
    <Dashboard>
      <Menu pointing secondary>
        <Menu.Item
          name="/dashboard"
          active={activeItem === "/dashboard" || activeItem === '/planned-visitor'}
          as={Link}
          to="/planned-visitor"
        >
          Planned Visitor
        </Menu.Item>
        <Menu.Item
          name="/regular-visitor"
          active={activeItem === "/regular-visitor"}
          as={Link}
          to="/regular-visitor"
        >
          Regular Visitor
        </Menu.Item>
      </Menu>

      {children}
    </Dashboard>
  );
};

export default observer(ManageMeetingLayout);
