import { observable, action, runInAction, computed } from "mobx";
import agent from "../API/agents";
import { RootStore } from "./rootStore";
import { parseISO, format } from 'date-fns';
import {
  IDateRangeVisitors,
  IDailyVisits,
  // IVisitAttendance,
  IReportData,
  IVisitsEnvelope,
  IPartnerEnvelope,
  IPartnerReport,
  IVisit,
} from "../models/reportModel";

import { history } from "..";
import { toast } from "react-toastify";
import { IPartnerData } from "../models/bookVisitModel";
import { differenceInMinutes } from "date-fns";
import { IBookedVisitorsReturnLists } from "../models/VisitorModel";
// import { th } from "date-fns/locale";

const LIMIT = 5;

export default class ReportStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable visitors: IVisitsEnvelope | null = null;
  @observable visitorsDateRangeEnvelope: IVisitsEnvelope | null = null;
  @observable visitorsDailyPlannedVisitsEnvelope: IVisitsEnvelope | null = null;

  @observable partnersDailyEnvelope: IPartnerEnvelope | null = null;
  @observable partnersDateRangeEnvelope: IPartnerEnvelope | null = null;

  @observable VisitorReportDataDaily: IReportData[] = [];
  @observable VisitorReportDataDateRange: IReportData[] = [];
  @observable DailyPlannedVisitsArr: IReportData[] = [];

  @observable DailyPartnerListExport: IPartnerData[] = [];
  @observable DateRangePartnerListExport: IPartnerData[] = [];

  @observable visitorCountDaily = 0;
  @observable visitorCountDateRange = 0;

  @observable pageDateRange = 1;
  @observable pageDaily = 1;
  @observable depot = "";
  @observable partnerDateRangeExport: IPartnerReport[] = [];
  @observable partnerDailyExport: IPartnerReport[] = [];
  @observable tempDepot: string = "";
  @observable modalOpen: boolean = false;
  @observable modalMessage: string = "";
  @observable  bookedVisitorsReturnList: IBookedVisitorsReturnLists  |null = null
  @observable  partnerList: IPartnerData[] = []

  @computed get totalPagesDaily() {
    return Math.ceil(this.visitorCountDaily / LIMIT);
  }
  @computed get totalPagesDateRange() {
    return Math.ceil(this.visitorCountDateRange / LIMIT);
  }

  formatTime(ind : Date) : string{  
    let ina : string;
    ina = ind.toString();
    let timeo:string;
    timeo = ina.split('T')[1].split(".")[0];
    return timeo;
    } 

  formatDate(ind : Date) : string{  
    let ina : string;
    ina = ind.toString();
    let dateo:string;
    dateo =  ina.split('T')[0].split('-')[2] + "-" + ina.split('T')[0].split('-')[1] + "-" + ina.split('T')[0].split('-')[0];
    return dateo;
    } 


// list of booked visitors
@action ListBookedVisitors = async (tenantId: string, depotId: string) =>{
  try {
    const result = await agent.bookedVisitor.list(tenantId, depotId);
    if (result) 
    this.bookedVisitorsReturnList = result;
  } catch (error) {
    
  }
}

//list partners by depot
@action resetPartnerList = async () =>{
  this.partnerList = []
}
@action ListPartners = async (tenantId: string, depotId: string) =>{
  try {
    const result = await agent.partner.listPartners(tenantId, depotId);
    if (result) 
      this.partnerList = result
  } catch (error) {
   
  }
}

// list of booked visitors
  @action ResetListBookedVisitors = async () =>
  {
    try {
      this.bookedVisitorsReturnList = null
    } catch (error) {}
  }
  @action setPageNumber_daily = (page: number) => (this.pageDaily = page);
  @action setPageNumber_DateRange = (page: number) =>(this.pageDateRange = page);
  @action changePage = async () =>
  this.DailyVisitorsReport({ depot: this.depot });
  @action changePageDateRange = async (data: IDateRangeVisitors) =>
  this.DateRangeVisitorsReport(data, this.pageDateRange);
  // partners report
  @action todaysPartnerReport = async (data: IDailyVisits) => {
    try {
      this.DailyPartnerListExport = [];

      // const partners = await agent.report.dailyPartners(
      //   data.depot,
      //   this.pageDaily
      // );

      // if (partners) {
      //   this.depot = data.depot;
      //   this.tempDepot = data.depot;
      //   this.partnersDailyEnvelope = partners;
      //   this.visitorCountDaily = partners.partnerCount;
      // }

      this.loadDailyPartnersReportListExport(data.depot);
    } catch (error) {
     
    }
  };
  loadDailyPartnersReportListExport = async (depot: string) => {
    this.partnerDailyExport = [];
    this.DailyPartnerListExport = [];
    const result = await agent.report.populateDailyPartnersList(depot);

    if (result.length > 0) {

      this.DailyPartnerListExport = result;

      this.DailyPartnerListExport.forEach((p, i) => {
        p.partnerAttendances.forEach((pa, i) => {
          let hours = 0;
          let min = 0;
          let stringHours = "";
          let stringMin = "";
          if (pa.isClockedOut && pa.isClockedIn) {
            hours = differenceInMinutes(pa.clockOut, pa.clockIn);
            if (hours > 60) {
              let tempVar = hours;
              hours = hours / 60;
              min = tempVar % 60;
              if (min <= 9) stringMin = "0" + min;
              else stringMin = "" + min;

              stringHours = Math.floor(hours) + ":" + stringMin;
            } else {
              if (hours <= 9) stringHours = "00:0" + hours;
              else stringHours = "0:" + hours;
            }
          }

          let partner: IPartnerReport = {
            workId: p.workId,
            name: p.name,
            agency: p.organisation,
            depot: pa.depot,
            signFlag: p.signFlag,
            badgeNumFlag: p.badgeNumFlag,
            depotBadgeNum: p.badgeNumFlag ? pa.depotBadgeNum : "NA",
            clockIn: pa.isClockedIn
              ? this.formatTime(pa.clockIn)
              : "Not clocked In",
            clockOut: pa.isClockedOut
              ? this.formatTime(pa.clockOut)
              : "Not clocked Out",
            temperature: pa.temperature ? pa.temperature : "NA",
            totalHours: stringHours,
          };
          this.partnerDailyExport.push(partner);
        });
      });

      toast.success('Please export the file now.')
      return
    }
    toast.info('No partners have clocked-in today')
  };
  @action dateRangePartnerReport = async (
    data: IDateRangeVisitors,
    page: number = 1
  ) => {
    // data.offset = page;
    // const partners = await agent.report.dateRangePartners(data);

    // if (partners) {
    //   this.depot = data.depot;
    //   this.tempDepot = data.depot;
    //   this.partnersDateRangeEnvelope = partners;
    //   this.visitorCountDaily = partners.partnerCount;
    // }

    this.loadDateRangePartnersReportListExport(data);
  };
  loadDateRangePartnersReportListExport = async (data: IDateRangeVisitors) => {
    this.partnerDateRangeExport = [];
    this.DateRangePartnerListExport = [];
    const result = await agent.report.populateDateRangePartnersList(data);

    if (result.length > 0) {
      this.DateRangePartnerListExport = result;

      this.DateRangePartnerListExport.forEach((p, i) => {
        p.partnerAttendances.forEach((pa, i) => {
          let hours = 0;
          let min = 0;
          let stringHours = "";
          let stringMin = ""; 
          if (pa.isClockedOut && pa.isClockedIn) { 

    
            let MS_IN_HOUR = 1000 * 60 * 60;
     
           let diffHrs : number;
           let endDate = new Date(pa.clockOut);
           let purchaseDate = new Date(pa.clockIn);
           let diffMs = (purchaseDate.valueOf() - endDate.valueOf()); // milliseconds
           if ( (Math.abs((diffMs % 86400000) / (MS_IN_HOUR))) < 1){
            diffHrs = 0;
           }else{
            diffHrs = Math.floor((diffMs % 86400000) / MS_IN_HOUR); 
           }
          // hours
           let diffMins = Math.round(((diffMs % 86400000) % MS_IN_HOUR) / 60000); // minutes
           stringHours=  ((diffHrs ) *-1) + " : " + ((diffMins )*-1)
  
          }

          let partner: IPartnerReport = {
            workId: p.workId,
            name: p.name,
            agency: p.organisation,
            depot: pa.depot, 
            signFlag: p.signFlag,
            badgeNumFlag: p.badgeNumFlag,
            depotBadgeNum: p.badgeNumFlag ? pa.depotBadgeNum : "NA",
            clockIn: pa.isClockedIn
              ? this.formatTime(pa.clockIn)
              : "Not clocked In",
            clockOut: pa.isClockedOut
              ? this.formatTime(pa.clockOut)
              : "Not clocked Out",
            temperature: pa.temperature ? pa.temperature : "NA",
            totalHours: stringHours,
          };
          this.partnerDateRangeExport.push(partner);
        });
      });
      toast.success('Please export the file now.')
      return
    }
    toast.info('No partners has clocked-in today')
  };
  // end
  @action viewQuestionnaire = async (visitor: IVisit) => {
    try {
      const result = await agent.report.viewVisitorQuestionnaire(
        visitor.id,
        visitor.depot.id
      );
      history.push(result.request);
    } catch (error) {
      throw error;
    }
  };
  @action cancelMeeting = async (visitRef: string) => {
    try {
      const result = agent.visitor.cancelVisit(visitRef);

      if (result) {
        toast.success("Meeting Canceled.");
        history.push("/reports");
      }
    } catch (error) {
      throw error;
    }
  };
  @action DateRangeVisitorsReport = async (
    data: IDateRangeVisitors,
    page: number = 1
  ) => {
    try {
      this.VisitorReportDataDateRange = [];
      data.offset = page;
      const visitors = await agent.report.dateRangeVisitors(data);
      if (visitors) {
        this.tempDepot = this.depot;
        this.visitorsDateRangeEnvelope = visitors;
        this.visitorCountDateRange = visitors.visitsCount;
      }else{
        toast.info('There are no visitors for the given dates')

      }
      this.populateDateRangeReportList(data) 
    } catch (error) {
     
      throw error;
    }
  };
  @action DailyVisitorsReport = async (data: IDailyVisits) => {
    try {
      this.VisitorReportDataDaily = [];

      const visitors = await agent.report.dailyVisitors(
        data.depot,
        this.pageDaily
      );
      if (visitors.visits.length > 0) {
        this.depot = data.depot;
        this.tempDepot = data.depot;
        this.visitors = visitors;
        this.visitorCountDaily = visitors.visitsCount;
      }else{
        toast.info('There are no visitors in the system yet')
      }

      //this.populateDailyReportList(data.depot);
    } catch (error) {
 
      throw error;
    }
  };
  @action getDailyPlannedVisitsReport = async (depot: IDailyVisits) => { 
    try {
      this.DailyPlannedVisitsArr = []

      const plannedVisits = await agent.report.dailyPlannedVisitor(depot.depot ,this.pageDaily);
     
      
      console.log("1   :   "  + plannedVisits);
      console.log(plannedVisits);
      console.log(plannedVisits.visit);
      if (plannedVisits.visit) {
        runInAction(() => {
          this.DailyPlannedVisitsArr = [];
          this.visitorsDailyPlannedVisitsEnvelope = {
            visits: plannedVisits.visit,
            visit: plannedVisits.visit,
            visitsCount: 0,
          };
        });
        console.log("2   :   "  + plannedVisits);
        console.log(plannedVisits);
        console.log(plannedVisits.visit);
        plannedVisits.visit.forEach((e) => {
          let obj: IReportData = {
            attendeeName: e.visit.attendee.firstName
              ? e.visit.attendee.firstName + " " + e.visit.attendee.lastName
              : "N/A",
            attendeeEmail: e.visit.attendee.email
              ? e.visit.attendee.email
              : "N/A",
            attendeeCompany: e.visit.attendee.company
              ? e.visit.attendee.company
              : "N/A",
            carReg: e.carReg ? e.carReg : "N/A",
            checkInTime: e.checkedIn
              ? this.formatTime(e.checkInTime)
              : "Yet to Check-in",
            checkOutTime: e.hasCheckedOut
              ? this.formatTime(e.checkOutTime)
              : "Yet to Checkout",
            dateOfVisit: this.formatDate(e.checkInTime),
            depoBadgeNum: e.depoBadgeNum ? e.depoBadgeNum : "N/A",
            depot: e.visit.depot.code,
            hostName: e.visit.host ? e.visit.host.name : "N/A",
            hostDepartment: e.visit.host ? e.visit.host.department : "N/A",
            hostEmail: e.visit.host ? e.visit.host.email : "N/A",
            hasCheckedOut: e.hasCheckedOut,
            visitReference: e.visit.visitReference,
            visitType: e.visit.visitType,
            reasonForVisit: e.reasonForVisit ? e.reasonForVisit : "N/A",
            manualCheckout: e.manualCheckout,
            temperature: e.temperature
              ? e.temperature
              : "N/A",
          };
          this.DailyPlannedVisitsArr.push(obj);
        });
      }else{
        toast.info('There are not planned visitors for today yet')

      }
    } catch (error) {
      throw error;
    }
  };

  @action populateDateRangeReportList = async (values: IDateRangeVisitors) => {
    try {
      this.VisitorReportDataDateRange = []
      const data = await agent.report.populateDateRangeVisitorsList(values);
      if (data) {
        data.forEach((e) => {
          const obj = {
            attendeeName:
              e.visit.attendee.firstName + " " + e.visit.attendee.lastName,
            attendeeEmail: e.visit.attendee.email,
            attendeeCompany: e.visit.attendee.company,
            carReg: e.carReg ? e.carReg : "N/A",

            checkInTime: e.checkedIn
              ? this.formatTime(e.checkInTime)
              : "Yet to Checkin",
            checkOutTime: e.hasCheckedOut
              ? this.formatTime(e.checkOutTime)
              : "Yet to Checkout",
            dateOfVisit: this.formatDate(e.checkInTime),
            depoBadgeNum: e.depoBadgeNum,
            depot: e.visit.depot.code,
            hostName: e.visit.host ? e.visit.host.name : "N/A",
            hostDepartment: e.visit.host ? e.visit.host.department : "N/A",
            hostEmail: e.visit.host ? e.visit.host.email : "N/A",
            hasCheckedOut: e.hasCheckedOut,
            visitReference: e.visit.visitReference,
            visitType: e.visit.visitType,
            reasonForVisit: e.reasonForVisit ? e.reasonForVisit : "N/A",
            manualCheckout: e.manualCheckout,
            temperature: e.temperature
              ? e.temperature
              : "N/A",
          };
          this.VisitorReportDataDateRange.push(obj);
        });


      }
    } catch (error) {
      throw error;
    }
  };
  @action closeModal() {
    this.modalOpen = !this.modalOpen;
  }
}
