import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext,
} from "react";
import {
  Grid,
  Segment,
  Form,
  Button,
  Table,
  Image,
  Dropdown,
  Message,
} from "semantic-ui-react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import logo from "../../assets/visIT_logo.png";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import HouseRules from "../Modals/HouseRules";
import SecurityRules from "../Modals/SecurityModal";
import HealthSafety from "../Modals/HealthSafety";
import OtherRules from "../Modals/OtherRules";
import TemperatureInstruction from "../Modals/TemperatureInstruction";
import ErrorMessage from "../layout/ErrorMessage";
import { history } from "../..";
import "../../style/visitor.css";

const UnplannedVisit = (props) => {
  const [inputName, setInputName] = useState("");
  const [layout, setLayout] = useState("default");
  const rootStore = useContext(RootStoreContext);
  const {
    unplannedProceedBtn,
    OpenConditionsWindow,
    onChangeSwitch,
    firstName,
    lastName,
    vReasonList,
    carReg,
    temperature,
    email,
    reason,
    axiosResponse,
    errors,
    company,
    healthSafetyWindow,
    otherRulesWindow,
    securityRulesWindow,
    houseRulesWindow,
    ChangeReasonForVisit,
    DennyConditions,
    AcceptConditions,
    closeTemWindow,
    virtualKeyboardVisible,
    hideVirtualKeyboardVisible,
    clearVisitorData,
    LoadReasonForVisit,
    openTempInstructionWin,
    temInstructionsFlag1,
    unplannedValidateForm,
    temInstructions,
  } = rootStore.visitorStore;

  const { depot, getDepot } = rootStore.depotStore;
  const keyboard = useRef();
  const setActiveInput = (event) => setInputName(event.target.id);

  const onChange = (input) => {
    onChangeSwitch(inputName, input);
  };
  const { match } = props;
  let { tenantName, depotName } = match.params;

  useEffect(() => {
    if (!depot) getDepot(tenantName, depotName);
 
    LoadReasonForVisit();
    unplannedValidateForm();
    hideVirtualKeyboardVisible(window.innerWidth);
  }, [
    clearVisitorData,
    getDepot,
    depot,
    depotName,
    unplannedValidateForm,
    hideVirtualKeyboardVisible,tenantName,
    LoadReasonForVisit,temInstructionsFlag1
  ]);
 
  const onChangeForm = (event) => {
    onChangeSwitch(event.target.name, event.target.value);
    keyboard.current.setInput(event.target.value);
  };

  const onChangReasonForVisit = (event, data) =>
    ChangeReasonForVisit(data.value);

  const handleShift = () =>
    setLayout(layout === "default" ? "shift" : "default");

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    OpenConditionsWindow("unplanned-visitor");
  };

  const BackHomeClick = () => {
    clearVisitorData();
    history.push(`/${depot.tenantName}/${depot.code}`);
  };
  return (
    <Fragment>
      {/* // show temperature instruction */}
      <TemperatureInstruction
        open={temInstructions && temInstructionsFlag1}
        accept={closeTemWindow}
      />
      {/* // end instructions */}

      <HouseRules
        open={houseRulesWindow}
        denny={DennyConditions}
        accept={AcceptConditions}
      />
      <SecurityRules
        open={securityRulesWindow}
        denny={DennyConditions}
        accept={AcceptConditions}
      />
      <HealthSafety
        open={healthSafetyWindow}
        denny={DennyConditions}
        accept={AcceptConditions}
      />
      <OtherRules
        open={otherRulesWindow}
        denny={DennyConditions}
        accept={AcceptConditions}
      />
      
      <Button
     style={{ marginBottom: 15, marginLeft: 25, marginTop: 20 }}
     size="big"
        icon="home"
        onClick={BackHomeClick}
        default
        content="Home"
      />
      <Grid centered style={{ marginTop: 5 }} className="formInput">
        <Image
          src={logo}
          style={{
            marginTop: 15,
          }}
        />

        <Grid.Row></Grid.Row>
        <Grid.Column width={11}>
          <Segment style={{ border: 0, marginTop: 10 }}>
            <Message warning style={{ fontSize: 16 }}>
              {" "}
              Please <strong>Note</strong>: All fields bellow must be completed!
            </Message>
            <Form onSubmit={handleSubmit} error method="post">
              <Table size="large" style={{ width: "100%", border: 0 }}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Form.Field>
                        <input
                          autoComplete={"off"}
                          id="fName"
                          name="fName"
                          type="text"
                          required
                          onChange={onChangeForm}
                          value={firstName}
                          placeholder="First Name"
                          onFocus={setActiveInput}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Field>
                        <input
                          autoComplete={"off"}
                          id="lName"
                          name="lName"
                          type="text"
                          required
                          value={lastName}
                          onChange={onChangeForm}
                          placeholder="Last Name"
                          onFocus={setActiveInput}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Field>
                        <input
                          autoComplete={"off"}
                          id="email"
                          name="email"
                          type="email"
                          required
                          value={email}
                          onChange={onChangeForm}
                          placeholder="Email"
                          onFocus={setActiveInput}
                        />
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Form.Field>
                        <input
                          autoComplete={"off"}
                          id="company"
                          name="company"
                          type="text"
                          required
                          value={company}
                          onChange={onChangeForm}
                          placeholder="Company"
                          onFocus={setActiveInput}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Group>
                        <Form.Field width="10">
                          <input
                            autoComplete={"off"}
                            id="carReg"
                            name="carReg"
                            type="text"
                            required
                            onChange={onChangeForm}
                            value={carReg}
                            placeholder="Car Registratrion"
                            onFocus={setActiveInput}
                          />
                        </Form.Field>
                        <Form.Field width="6">
                          {temInstructionsFlag1 ? (
                            <input
                              autoComplete={"off"}
                              id="temp"
                              name="temperature"
                              type="text"
                              required
                              onClick={openTempInstructionWin}
                              onChange={onChangeForm}
                              value={temperature}
                              placeholder="Temperature"
                              onFocus={setActiveInput}
                            />
                          ) : (
                            <input
                              autoComplete={"off"}
                              id="temperature"
                              name="temperature"
                              type="text"
                              required
                              onChange={onChangeForm}
                              value={temperature}
                              placeholder="Temperature"
                              onFocus={setActiveInput}
                            />
                          )}
                        </Form.Field>
                      </Form.Group>
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Field>
                        <Dropdown
                          required={true}
                          className="vReasonClass"
                          onChange={onChangReasonForVisit}
                          value={reason}
                          placeholder="Reason For Visit"
                          fluid
                          selection
                          name="reason"
                          options={vReasonList}
                        />
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Button
                size="big"
                fluid
                disabled={unplannedProceedBtn}
                style={{
                  backgroundColor: "#4db25e",
                  color: "#FFF",
                  fontSize: 15,
                  borderRadius: 10,
                }}
              >
                Proceed
              </Button>
            </Form>
          </Segment>
        </Grid.Column>
        <Grid.Row centered>
          <Grid.Column width={15}>
            {errors && <ErrorMessage error={axiosResponse} text={errors} />}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {virtualKeyboardVisible && (
        <div className="keyboardPosition">
          <Keyboard
            inputName={inputName}
            useMouseEvents={true}
            keyboardRef={(r) => (keyboard.current = r)}
            layoutName={layout}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        </div>
      )}
      {!virtualKeyboardVisible && (
        <div>
          {" "}
          <br />
          <br />
          <br />
        </div>
      )}

      {/* <div className="BottomNam">
        <NavBar backgroundColor={"#be2332"} height={20} />
      </div> */}
    </Fragment>
  );
};
export default observer(UnplannedVisit);
