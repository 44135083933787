import React, { useContext } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { Link, Redirect } from "react-router-dom";
import { Form as FinalForm, Field } from "react-final-form";
import { Form, Button, Segment, Grid } from "semantic-ui-react";
import TextInput from "../../common/form/TextInput";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import { ILoginModel } from "../../models/LoginModel";
import ErrorMessage from "../layout/ErrorMessage";
import AnonymousLayout from "../layout/AnonymousLayout";

const validate = combineValidators({
  username: isRequired("username"),
  password: isRequired("password"),
});

export default function Login() {
  const rootStore = useContext(RootStoreContext);
  const { Login} = rootStore.userStore;

 
    return (
      <AnonymousLayout>
        <Grid.Column width={6}>
          <Segment clearing>
            <FinalForm
              onSubmit={(values: ILoginModel) =>
                Login(values).catch((error) => ({
                  [FORM_ERROR]: error,
                }))
              }
              validate={validate}
              render={({
                handleSubmit,
                submitting,
                submitError,
                invalid,
                pristine,
                dirtySinceLastSubmit,
              }) => (
                <Form onSubmit={handleSubmit} error>
                  <Field
                    label="Username"
                    name="username"
                    placeholder="Username"
                    component={TextInput}
                  />
                  <Field
                    label="Password"
                    icon="lock"
                    name="password"
                    type="password"
                    placeholder="Password"
                    component={TextInput}
                  />

                  <Link to="/forgot-password">Reset Password</Link>
                  {submitError && !dirtySinceLastSubmit &&  
                  
                    // <ErrorMessage
                    //   error={submitError}
                    //   text={"Invalid Username or Password"}
                    // />
             (<ErrorMessage error={submitError} text={submitError} />
                  )}
                  <br></br>
                  <Button
                    fluid
                    icon="sign in"
                    loading={submitting}
                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                    primary
                    content="Login"
                  />
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </AnonymousLayout>
    );
}
