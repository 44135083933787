import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import ManageTenantLayout from "../../layout/ManageTenantLayout";
import { Redirect } from "react-router-dom";
import { RootStoreContext } from "../../../stores/rootStore";
import {  ITenantFormValues } from "../../../models/tenant";
import { Field, Form as FinalForm } from "react-final-form";
import { combineValidators, isRequired, matchesField } from "revalidate";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { FORM_ERROR } from "final-form";
// import SelectInput from "../../../common/form/SelectInput";
import TextInput from "../../../common/form/TextInput";
// import { IDepotFormValues } from "../../../models/depot";
import ErrorMessage from "../../layout/ErrorMessageAddTenant";
// //http://revalidate.jeremyfairbank.com/common-validators/matchesField.html
const validate = combineValidators({
  name: isRequired("Name"),
  repEmail: isRequired("Representative Email"),
  repEmail_confirmation: matchesField(
    "repEmail",
    "repEmail_confirmation"
  )({ message: "Emails do not match" }),
  code: isRequired("Code"),
  repName: isRequired("Representative Name"),
  line1: isRequired("Line 1"),
  line2: isRequired("Line 2"),
  county:isRequired("County"),
  eirCode:isRequired("EirCode"),
 
});
const AddTenant = () => {
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.userStore;
  const { addTenant } = rootStore.tenantStore;

  if (user?.role !== "SuperAdmin") return <Redirect to="/dashboard" />;
  return (
    <ManageTenantLayout>
      <Grid centered>
        <Grid.Column width={15}>
          <Header as="h2" attached="top">
            Add Tenant
          </Header>
          <br />
          <FinalForm
            onSubmit={(values: ITenantFormValues) =>
              addTenant(values).catch((error) => ({
                [FORM_ERROR]: error,
              }))
            }
          validate={validate}
            render={({
              handleSubmit,
              submitting,
              submitError,
              invalid,
              pristine,
              dirtySinceLastSubmit,
            }) => (
              <Form onSubmit={handleSubmit} error>
                <Segment clearing>
                  <Header as="h3" content="Tenant Details" />
                  <Form.Group widths="equal">
                    <Field
                      label="Name"
                      name="name"
                      placeholder="Name"
                      component={TextInput}
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Field
                      label="Representative Name"
                      name="repName"
                      placeholder="Representative Name"
                      component={TextInput}
                    />

                    <Field
                      label="Username"
                      name="username"
                      placeholder="Username"
                      component={TextInput}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    
                    <Field
                      label="Representative Email"
                      name="repEmail"
                      placeholder="Representative Email"
                      type="email"
                      component={TextInput}
                    />
                    <Field
                      label="Repeat Representative Email"
                      name="repEmail_confirmation"
                      placeholder="Confirm Email"
                      type="email"
                      component={TextInput}
                    />
                  </Form.Group>
                  <Form.Group></Form.Group>
                </Segment>

                <Segment clearing>
                  <Header as="h3" content="Location/ Depot Details" />
                  <Field
                    label="Code"
                    name="code"
                    placeholder="Code"
                    component={TextInput}
                  />

                  <Form.Group widths="equal">
                    <Field
                      label="Line 1"
                      name="line1"
                      type="text"
                      placeholder="Line 1"
                      component={TextInput}
                    />
                    <Field
                      label="Line 2"
                      name="line2"
                      type="text"
                      placeholder="Line 2"
                      component={TextInput}
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Field
                      label="County"
                      name="county"
                      type="text"
                      placeholder="County"
                      component={TextInput}
                    />
                    <Field
                      label="EirCode"
                      name="eirCode"
                      type="text"
                      placeholder="EirCode"
                      component={TextInput}
                    />
                  </Form.Group>
                </Segment>

                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage error={submitError} />
                )}

                <br />
                <Button
                  icon="signup"
                  loading={submitting}
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  positive
                  labelPosition="left"
                  content="Register"
                />
              </Form>
            )}
          />
        </Grid.Column>
      </Grid>
    </ManageTenantLayout>
  );
};

export default observer(AddTenant);
