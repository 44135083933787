import React, { useContext, useRef, useState, useEffect } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { Grid, Icon, Divider, List, Button } from "semantic-ui-react";
import Clock from "react-live-clock";
import { Container } from "reactstrap";
import SignatureCanvas from "react-signature-canvas";
import CheckInMessage from "../Modals/CheckInMessage";
import { observer } from "mobx-react-lite";


const PrintHouseRules = (props) => {
  const rootStore = useContext(RootStoreContext);

  const {
    visitor,
    carReg,
    temperature,
    depotBadgeNum,
    hr_1,
    hr_2,
    hr_3,
    hr_4,
    hr_5,
    sr_1,
    sr_2,
    hsr_1,
    hsr_2,
    hsr_3,
    hsr_4,
    hsr_5,
    hsr_6,
    or_1,
    checkInWindow,
    PrintHouseRulesClick,
    or_2,
  } = rootStore.visitorStore;

  let canvasRef = useRef(null);
  const [disabled, SetDisabled] = useState(true);
  const isEmpty = () => SetDisabled(canvasRef.isEmpty());
  const { depot, getDepot, depotLocation, getDepotLocation } = rootStore.depotStore;
  const save = () =>
    PrintHouseRulesClick(
      dataURItoBlob(canvasRef.getCanvas().toDataURL("image/png", 1))
    );

  const { match } = props;
  let { depotCode, tenantName, regularFlag } = match.params;

  useEffect(() => {
    if (!depot) {
      getDepot(tenantName, depotCode);
    }
    getDepotLocation(depot.address)
  }, [depotCode,tenantName, getDepot, depot,getDepotLocation, visitor]);

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  return (
    <Container>
      <CheckInMessage depotBadgeNum={depotBadgeNum} open={checkInWindow} />

      <div className="printPage">
        <h2 style={{ textAlign: "center" }}>
          Welcome to {depot.tenantName}, {depot.code.toUpperCase()}, {depotLocation}
        </h2>

        <Grid centered>
          <Grid.Column width={8}>
            <h4>Please confirm you details below: </h4>

            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Name</strong>
                  </td>
                  <td>
                    {visitor?.firstName} {visitor?.lastName}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Company</strong>
                  </td>
                  <td>{visitor?.company}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Email Address</strong>
                  </td>
                  <td>{visitor?.email}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Vehicle Registration Number</strong>
                  </td>
                  <td>{carReg}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Temperature</strong>
                  </td>
                  <td>{temperature}</td>
                </tr>
              </tbody>
            </table>
          </Grid.Column>

          <Grid.Column width={4}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Clock date={Date.now()} format={"dddd"} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Clock date={Date.now()} format={"Do MMM YYYY"} /> at
                    <Clock date={Date.now()} format={"HH:mm"} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid.Column>

          <Grid.Column width={4}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Depot Badge Id</strong>
                  </td>
                </tr>
                <tr>
                  <td>{depotBadgeNum}</td>
                </tr>
              </tbody>
            </table>
          </Grid.Column>
        </Grid>

        <table>
          <tbody>
            <tr>
              <td>
                <h4>House Rules</h4>
              </td>
            </tr>
            <tr>
              <td>
                Take note of and read our information signs, posters and notice
                boards.
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {hr_1 && <Icon color="green" name="checkmark" size="large" />}
              </td>)}
            </tr>
            <tr>
              <td>
                If so requested, I agree to be temperature tested in advance of
                my visit and will abide by all social distancing measures whilst
                visiting.
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {hr_2 && <Icon color="green" name="checkmark" size="large" />}
              </td>)}
            </tr>
            <tr>
              <td>
                Display your visitors pass at all times on the outside of your
                clothes.
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {hr_3 && <Icon color="green" name="checkmark" size="large" />}
              </td>)}
            </tr>
            <tr>
              <td>
                In completing this questionnaire, you confirm for the duration
                of your visit, you will adhere to all food hygiene, COVID-19 and
                security protocols that Masterlink have in place.
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {hr_4 && <Icon color="green" name="checkmark" size="large" />}
              </td>)}
            </tr>
            <tr>
              <td>
                <h4>Security</h4>
              </td>
            </tr>
            <tr>
              <td>
                During your visit security must be observed at all times, please
                acknowledge the following:
                <Divider horizontal />
                <List as="ul" style={{ marginLeft: 80 }}>
                  <List.Item as="li">
                    From the moment you arrive, you are under CCTV surveillance.
                  </List.Item>
                  <List.Item as="li">
                    All entrance and exits are guarded with physical access
                    control.
                  </List.Item>
                  <List.Item as="li">
                    Do not be offended if challenged, staff members are to do
                    this.
                  </List.Item>
                </List>
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {sr_1 && <Icon color="green" name="checkmark" size="large" />}
              </td>)}
            </tr>
            <tr>
              <td>
                I confirm that I will not force or enter any area without your
                escort. When entering the Operations area, I will remain with
                the escort at all times.
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {sr_2 === "yes" ? (
                  <Icon color="green" name="checkmark" size="large" />
                ) : (
                  <Icon color="red" name="close" size="large" />
                )}
              </td>)}
            </tr>

            <tr>
              <td>
                <h4>Health & Safety</h4>
              </td>
            </tr>
            <tr>
              <td>
                I confirm that in the last 14 days I did not have any symptoms
                of COVID-19, such as (Cough, Fever, High Temperature, Score
                Throat, Runny Nose, Breathlessness).
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
                <td>
                  {hsr_1 === "no" ? (
                    <Icon color="green" name="checkmark" size="large" />
                  ) : (
                    <Icon color="red" name="close" size="large" />
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td>
                I confirm that in the last 14 days I was not diagnosed or
                suspected COVID-19 or in close contact with a person/s with
                diagnosed or suspected cases.
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {hsr_2 === "no" ? (
                  <Icon color="green" name="checkmark" size="large" />
                ) : (
                  <Icon color="red" name="close" size="large" />
                )}
              </td>)}
            </tr>
            <tr>
              <td>
                I confirm that that I had not been advised by a Doctor to
                self-isolate or cocoon at this time.
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {hsr_3 === "no" ? (
                  <Icon color="green" name="checkmark" size="large" />
                ) : (
                  <Icon color="red" name="close" size="large" />
                )}
              </td>)}
            </tr>

            <tr>
              <td>
                I confirm that I have not, in last 14 days, travelled to any
                Country that is not in the Government Travel Green list.
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {hsr_4 === "no" ? (
                  <Icon color="green" name="checkmark" size="large" />
                ) : (
                  <Icon color="red" name="close" size="large" />
                )}
              </td>)}
            </tr>
            <tr>
              <td>
                I confirm that I will respect internal partitions within the
                warehouse for your safety.
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {hsr_5 === "yes" ? (
                  <Icon color="green" name="checkmark" size="large" />
                ) : (
                  <Icon color="red" name="close" size="large" />
                )}
              </td>)}
            </tr>
            <tr>
              <td>
                I confirm that in last 14 days I did not suffer from any of the
                following: Vomiting; Diarrhea; Stomach infection; Eye, ear, nose
                infection; Bacterial infection that could cause food poisoning;
                or Salmonella
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {hsr_6 === "no" ? (
                  <Icon color="green" name="checkmark" size="large" />
                ) : (
                  <Icon color="red" name="close" size="large" />
                )}
              </td>)}
            </tr>

            <tr>
              <td>
                <h4>Other</h4>
              </td>
            </tr>
            <tr>
              <td>
                A breach of any one (or all) of these House Rules will result in
                you being removed from the warehouse.
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {hr_5 && <Icon color="green" name="checkmark" size="large" />}
              </td>)}
            </tr>
            <tr>
              <td>
                I agreed to be contacted by Masterlink by email after this visit
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {or_1 && <Icon color="green" name="checkmark" size="large" />}
              </td>)}
            </tr>
            <tr>
              <td>
                I accept that Masterlink will retain details of my visit and may
                share them with others after my visit
              </td>
              {regularFlag === "regular" ? (
                <td>
                  <Icon color="green" name="checkmark" size="large" />
                </td>
              ) : (
              <td>
                {or_2 && <Icon color="green" name="checkmark" size="large" />}
              </td>)}
            </tr>
          </tbody>
        </table>
        <br />
        <Grid>
          <Grid.Column width={10}>
            <strong>Please sign here to confirm.</strong>
            <br />
            <div style={{ backgroundColor: "#f3f3f3" }}>
              <SignatureCanvas
                penColor="black"
                onBegin={isEmpty}
                onEnd={isEmpty}
                ref={(c) => (canvasRef = c)}
                canvasProps={{
                  width: 650,
                  height: 160,
                  className: "sigCanvas",
                }}
              />
            </div>
          </Grid.Column>
          <Grid.Column width={5}>
            <div style={{ paddingTop: 95, marginTop: 90 }}>
              <Clock date={Date.now()} format={"dddd Do MMM YYYY"} /> at
              <Clock date={Date.now()} format={"HH:mm"} />{" "}
            </div>
          </Grid.Column>

          <Grid.Column width={6}>
            <Button
              onClick={() => canvasRef.clear() & SetDisabled(true)}
              icon="delete"
              fluid
              color={"red"}
              content="Clear Signature"
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <Button
              onClick={save}
              icon="save"
              color={"green"}
              fluid
              disabled={disabled}
              content="Complete"
            />
          </Grid.Column>
        </Grid>
      </div>
    </Container>
  );
};

export default observer(PrintHouseRules);
