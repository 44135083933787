import React, { useContext, useState } from "react";
import {
  Navbar,
  Container,
  NavItem,
  NavbarBrand,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { RootStoreContext } from "../../stores/rootStore";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import logo from "../../assets/visIT_nav_logo.png";
import { observer } from "mobx-react-lite";

const AdminNavBar = () => {
  const rootStore = useContext(RootStoreContext);
  const { logout, user, isLoggedIn } = rootStore.userStore;
 

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
 
  return (
    <Navbar
      style={{ backgroundColor: "#F8F9FA", height:77 }}
      className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 dark"
      
    >
      <Container fluid style={{ backgroundColor: "#F8F9FA" }}
      className="mb-3 mt-3">
        <NavbarBrand tag={Link} to={"/"}>
          <img
            src={logo}
            style={{  height: "65px" }}
            className="float-right nav-logo"
            alt="Masterlink VisIT Logo"
          />
        </NavbarBrand>

        <ul className="navbar-nav flex-grow">
          {isLoggedIn ? (
            <NavItem>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                className="nav-link"
              >
                <DropdownToggle
                  style={{ backgroundColor: "#F8F9FA", color: "#000000" }}
                  caret
                >
                  {user && <Icon name="user circle" />}
                  {user ? user.username : false}
                </DropdownToggle>
                <DropdownMenu style={{ backgroundColor: "#F8F9FA" }}>
                  <DropdownItem onClick={logout}>
                    Logout <Icon name="sign-out" size="small" />
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
          ) : (
            false
          )}
        </ul>
      </Container>
    </Navbar>
  );
};

export default observer(AdminNavBar);
