import React from "react";
import { Navbar } from "reactstrap";

const NavBar = props => {
    return (
        <header>
            <Navbar
                style={{ backgroundColor: props.backgroundColor, height: props.height }}
                className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
                light
            >
            </Navbar>
        </header>
    );
}

export default NavBar;