import React, { useContext } from "react";
import ErrorMessage from "../layout/ErrorMessage";
import { Form as FinalForm, Field } from "react-final-form";
import { Grid, Segment, Form, Button } from "semantic-ui-react";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import TextInput from "../../common/form/TextInput";
import { IUser } from "../../models/userModel";
import AnonymousLayout from "../layout/AnonymousLayout";

const validate = combineValidators({
  username: isRequired("username"),
});

function ForgotPassword() {
  const rootStore = useContext(RootStoreContext);
  const { RequestPassword } = rootStore.userStore;

  return (
    <AnonymousLayout>
      <Grid.Column width={6}>
        <Segment clearing>
          <h2>Forgot password</h2>
          <FinalForm
            onSubmit={(user: IUser) =>
              RequestPassword(user).catch((error) => ({
                [FORM_ERROR]: error,
              }))
            }
            validate={validate}
            render={({
              handleSubmit,
              submitting,
              submitError,
              invalid,
              pristine,
              dirtySinceLastSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Field
                  label="Username"
                  name="username"
                  placeholder="Username"
                  component={TextInput}
                />
                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage
                    error={submitError}
                    text={"Error resetting password"}
                  />
                )}
                <br></br>
                <Button
                  fluid
                  icon="key"
                  loading={submitting}
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  primary
                  content="Reset password"
                />
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>
    </AnonymousLayout>
  );
}

export default observer(ForgotPassword);
