import React, { useContext } from "react";
import {
    Modal,
    Table,
    Checkbox,
    Button,
    Divider,
    List,
    CheckboxProps,
    Form,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";

interface IProps {
    open: boolean;
    accept: (name: string) => void;
    denny: (name: string) => void;
}

const HealthSafety: React.FC<IProps> = ({ open, accept, denny }) => {
    const rootStore = useContext(RootStoreContext);
    const {
        hsr_1,
        hsr_2,
        hsr_3,hr_5,
        hsr_4,onchangeHouseRules,
        hr_4,
        hsr_6, or_1, or_2,
        hsr_proceedBtn,
        onchangeHealthSafetyRules, CloseModalHSR,
    } = rootStore.visitorStore;

    return (
      <Modal
        className="health-safety-modal"
        // style={{ height: 804, left: "auto", right: "auto", marginTop: 40 }}
        open={open}
        closeOnDimmerClick
        onClose={CloseModalHSR}
        size="small"
      >
        <Modal.Header>House Rules</Modal.Header>
        <Modal.Content>
          <Table striped>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  Have you within the last 14 days had any symptoms of Covid-19,
                  such as (Cough, Fever, High Temperature, Score Throat, Runny
                  Nose, Breathlessness)?
                </Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Checkbox
                      label="No"
                      value="no"
                      name="hsr_1"
                      checked={hsr_1 === "no"}
                      onChange={(
                        event: React.FormEvent<HTMLInputElement>,
                        data: CheckboxProps
                      ) => onchangeHealthSafetyRules(event, data)}
                    />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Have you within the last 14 days been diagnosed with confirmed
                  or suspected Covid-19 or in close contact with a person/s with
                  diagnosed or suspected cases?
                </Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Checkbox
                      label="No"
                      value="no"
                      name="hsr_2"
                      checked={hsr_2 === "no"}
                      onChange={(
                        event: React.FormEvent<HTMLInputElement>,
                        data: CheckboxProps
                      ) => onchangeHealthSafetyRules(event, data)}
                    />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Have you been advised by a Doctor to self-isolate or cocoon at
                  this time?
                </Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Checkbox
                      label="No"
                      value="no"
                      name="hsr_3"
                      checked={hsr_3 === "no"}
                      onChange={(
                        event: React.FormEvent<HTMLInputElement>,
                        data: CheckboxProps
                      ) => onchangeHealthSafetyRules(event, data)}
                    />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  In the last 14 days have you travelled to any Country that is
                  not in the Government Travel Green lis?
                </Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Checkbox
                      label="No"
                      value="no"
                      name="hsr_4"
                      checked={hsr_4 === "no"}
                      onChange={(
                        event: React.FormEvent<HTMLInputElement>,
                        data: CheckboxProps
                      ) => onchangeHealthSafetyRules(event, data)}
                    />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  In completing this questionnaire, you confirm for the duration
                  of your visit, you will adhere to all food hygiene, Covid-19
                  and security protocols that Masterlink have in place.
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    label="Accept"
                    name="hr_4"
                    checked={hr_4}
                    onChange={(
                      event: React.FormEvent<HTMLInputElement>,
                      data: CheckboxProps
                    ) => onchangeHouseRules(event, data)}
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  Have you within the last 14 days suffered from any of the
                  following:
                  <Divider horizontal />
                  <List as="ul" style={{ marginLeft: 80 }}>
                    <List.Item as="li">Vomiting</List.Item>
                    <List.Item as="li">Diarrhea</List.Item>
                    <List.Item as="li">Stomach infection</List.Item>
                    <List.Item as="li">Eye, ear, nose infection</List.Item>
                    <List.Item as="li">
                      Bacterial infection that could cause food poisoning
                    </List.Item>
                    <List.Item as="li">Salmonella</List.Item>
                  </List>
                  <Divider horizontal />
                </Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Checkbox
                      label="No"
                      value="no"
                      name="hsr_6"
                      checked={hsr_6 === "no"}
                      onChange={(
                        event: React.FormEvent<HTMLInputElement>,
                        data: CheckboxProps
                      ) => onchangeHealthSafetyRules(event, data)}
                    />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
             
             
              <Table.Row>
                <Table.Cell>
                  A breach of any one (or all) of these House Rules will result
                  in you being removed from the premises.
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    label="Accept"
                    name="hr_5"
                    checked={hr_5}
                    onChange={( event: React.FormEvent<HTMLInputElement>,
                      data: CheckboxProps
                    ) => onchangeHouseRules(event, data)}
                  />
                </Table.Cell>
              </Table.Row>
             
              <Table.Row>
                <Table.Cell>
                  I agreed to be contacted by Masterlink by email after this
                  visit.
                </Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Checkbox
                      label="Accept"
                      value="yes"
                      name="or_1"
                      checked={or_1 === "yes"}
                      onChange={(
                        event: React.FormEvent<HTMLInputElement>,
                        data: CheckboxProps
                      ) => onchangeHealthSafetyRules(event, data)}
                    />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  I accept that Masterlink will retain details of my visit and
                  may share them with others after my visit.
                </Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Checkbox
                      label="Accept"
                      value="yes"
                      name="or_2"
                      checked={or_2 === "yes"}
                      onChange={(
                        event: React.FormEvent<HTMLInputElement>,
                        data: CheckboxProps
                      ) => onchangeHealthSafetyRules(event, data)}
                    />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <div className="modalPageNum">
            <p> Page 2 of 2</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div className="modal-footer">
            <div className="action-text">
              <p>Your Health & Safety is important to us!</p>
            </div>

            <Button
              disabled={!hsr_proceedBtn}
              positive
              onClick={() => accept("HeathSafetyRules")}
              content="Proceed"
            />
          </div>
        </Modal.Actions>
      </Modal>
    );
};

export default observer(HealthSafety);