import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

interface IProps {
    open: boolean;
    hasReferenceRegular: (name: string) => void;
}
const VisitorCodeOptionRegularVisitor: React.FC<IProps> = ({
    open,
    hasReferenceRegular,
}) => {
    return (
      <Modal className="code-option-modal" open={open} size="small">
        <Modal.Header>
          Do you a RV number and your six digit PIN? <br />
          This may have been emailed to you.
        </Modal.Header>
        <Modal.Actions>
          <Button
            positive
            onClick={() => hasReferenceRegular("yes")}
            content="Yes"
          />
          <Button
            color="red"
            onClick={() => hasReferenceRegular("no")}
            content="No"
          />
        </Modal.Actions>
      </Modal>
    );
};

export default observer(VisitorCodeOptionRegularVisitor);