import React, {  useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Dashboard from "./Dashboard";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

interface IProps {
  children: any;
}

const ReportLayout: React.FC<IProps> = ({ children }) => {
 // const rootStore = useContext(RootStoreContext);
 // const [loading, setLoading] = useState(true);
 // const { ListDepot, depots } = rootStore.depotStore;
  const [activeItem, setActiveItem] = useState("/dashboard");

  useEffect(() => {
    setActiveItem(window.location.pathname);
  }, [setActiveItem]);

  return (
    <Dashboard>
      <Menu pointing secondary>
        <Menu.Item
          name="/dashboard"
          active={activeItem === "/reports"}
          as={Link}
          to="/reports"
        >
          Visitors Report
        </Menu.Item>
        <Menu.Item
          name="/partner-report"
          active={activeItem === "/partner-report"}
          as={Link}
          to="/partner-report"
        >
          Partners Report
        </Menu.Item>
        <Menu.Item
          name="/registered-partners" 
          active={activeItem === "/registered-partners"}
          as={Link}
          to="/registered-partners"
        >
          Partners
        </Menu.Item>
        <Menu.Item
          name="/booked-visitors"
          active={activeItem === "/booked-visitors"}
          as={Link}
          to="/booked-visitors"
        >
          Visitors
        </Menu.Item>
      
       
      </Menu>

      {children}
    </Dashboard>
  );
};

export default observer(ReportLayout);
