import React, { useContext, useEffect } from "react";
import { Button, Grid, Image, Form } from "semantic-ui-react";
import logo from "../../assets/visIT_logo.png";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { history } from "../..";
import PrintPartnerHouseRules from "./PrintPartnerHouseRules";

const PartnerPrintHouseRulesContainer = (props) => {
  const rootStore = useContext(RootStoreContext);
  const {
    clearVisitorData,
    depotBadgeNum,
    workId,
    temperature,
    partnerData,
  } = rootStore.partnerStore;
  const { depot, getDepot } = rootStore.depotStore;

  const BackHomeClick = () => {
    clearVisitorData();
    history.push(`/${tenantName}/${depotCode}`);
  };
  const { match } = props;
  let { tenantName, depotCode } = match.params;

  useEffect(() => {
    if (!depot) getDepot(tenantName, depotCode);
  }, [depot, getDepot, depotCode, tenantName]);

  return (
    <div>
      <Button
        style={{ marginBottom: 15, marginLeft: 25, marginTop: 20 }}
      size="big"
        icon="home"
        onClick={BackHomeClick}
        default
        content="Home"
      />

      <Grid columns={2} centered style={{ marginTop: 5 }}>
        <Image
          src={logo}
          style={{
            marginTop: 20,
            marginBottom: 25,
          }}
        />
      </Grid>
      <Grid centered>
        <Grid.Row columns="2" centered>
          <Grid.Column width="5">
            <Form method="post">
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Work ID</label>
                  <input
                    readOnly
                    style={{ fontSize: 18, borderWidth: 2, minWidth: 250 }}
                    id="workId"
                    name="workId"
                    type="text"
                    value={workId}
                    placeholder="Work ID"
                    autoComplete={"off"}
                  />
                </Form.Field>
                <Form.Field>
                  <Button
                    style={{
                      marginTop: 25,
                      marginBottom: 15,
                      marginLeft: 25,
                      backgroundColor: "#4DB25E",
                      color: "#f3f3f3",
                    }}
                    size="big"
                    icon="sign-in"
                    primary
                    disabled={true}
                    content="Submit Temp."
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            {true && (
              <Form method="post">
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Temperature</label>
                    <input
                      readOnly
                      style={{
                        fontSize: 18,
                        borderWidth: 2,
                        minWidth: 250,
                      }}
                      id="partnerTemp"
                      name="partnerTemp"
                      type="text"
                      value={temperature}
                      placeholder="Temperature"
                      autoComplete={"off"}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Button
                      style={{
                        marginTop: 25,
                        marginBottom: 15,
                        marginLeft: 25,
                        backgroundColor: "#4DB25E",
                        color: "#f3f3f3",
                      }}
                      size="big"
                      icon="sign-in"
                      primary
                      disabled={true}
                      content="Submit Temp."
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
            {partnerData.badgeNumFlag && (
              <Form method="post">
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Badge Number</label>
                    <input
                      required
                      style={{
                        fontSize: 18,
                        borderWidth: 2,
                        minWidth: 250,
                      }}
                      id="badgeNum"
                      name="badgeNum"
                      type="text"
                      value={depotBadgeNum}
                      readOnly
                      placeholder="Badge Number"
                      autoComplete={"off"}
                    />
                  </Form.Field>

                  <Form.Field>
                    <Button
                      icon="sign-in"
                      style={{
                        marginTop: 25,
                        marginBottom: 15,
                        marginLeft: 25,
                        width: 190,
                        backgroundColor: "#4DB25E",
                        color: "#f3f3f3",
                      }}
                      size="big"
                      primary
                      disabled={true}
                      content="Submit"
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Grid.Column>
          <Grid.Column width="7">
            <PrintPartnerHouseRules depot={depot} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default observer(PartnerPrintHouseRulesContainer);
