import { observable, action, runInAction } from "mobx";
import agent from "../API/agents";
import { RootStore } from "./rootStore";
import {
  IVisitorModel,
  ICheckInAttendee,
  IPassCode,
} from "../models/VisitorModel";
import { toast } from "react-toastify";
import { history } from "..";
import agents from "../API/agents";
import { CheckboxProps } from "semantic-ui-react";
import { SyntheticEvent } from "react";
import { IVReason } from "../models/visitReason";
import { IEmailNotification } from "../models/userModel";
import { AxiosResponse } from "axios";

export default class VisitorStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable vReasonList: IVReason[] = [];
  // modals flags
  @observable checkoutWindow: boolean = false;
  @observable checkInWindow: boolean = false;
  @observable securityRulesWindow: boolean = false;
  @observable houseRulesWindow: boolean = false;
  @observable otherRulesWindow: boolean = false;
  @observable healthSafetyWindow: boolean = false;

  @observable scrollDownFlag: boolean = false;

  @observable visitor: ICheckInAttendee | null = null;
  @observable firstName: string = "";
  @observable lastName: string = "";
  @observable email: string = "";
  @observable reason: string = "";
  @observable company: string = "";
  @observable carReg: string = "";
  @observable escort: string = "";
  @observable meetingWith: string = "";
  @observable department: string = "";

  @observable highlightInputBorderCarReg: boolean = false;
  @observable highlightInputBorderTemp: boolean = false;

  // reg visitor ref
  @observable regVisitorRef: string = "";
  @observable meetingRefOrBadgeNum: string = "";
  @observable depotBadgeNum: string = "";
  @observable temperature: string = "";

  // need to make it generic

  // covid self declaration form
  @observable one_ans: string = ""!;
  @observable two_ans: string = "";
  @observable three_ans: string = "";
  @observable four_ans: string = "";
  @observable five_ans: string = "";
  @observable six_ans: string = "";
  @observable visitPath: string = "";

  // regular visitor
  @observable _GlobalPassCodeArr: number[] = [];
  @observable passCode_char_1_index: string = "";
  @observable passCode_char_2_index: string = "";
  @observable passCode_char_3_index: string = "";

  @observable passCode_char_1_val: string = "";
  @observable passCode_char_2_val: string = "";
  @observable passCode_char_3_val: string = "";

  // house rules
  @observable hr_1: boolean = false;
  @observable hr_2: boolean = false;
  @observable hr_3: boolean = false;
  @observable hr_4: boolean = false;
  @observable hr_5: boolean = false;
  @observable hr_proceedBtn: boolean = false;

  @observable virtualKeyboardVisible: boolean = true;

  // security Rules
  @observable sr_1: boolean = false;
  @observable sr_2: string = "";
  @observable sr_proceedBtn: boolean = false;

  // HEALTH AND SAFETY
  @observable hsr_1: string = "";
  @observable hsr_2: string = "";
  @observable hsr_3: string = "";
  @observable hsr_4: string = "";
  @observable hsr_5: string = "";
  @observable hsr_6: string = "";
  @observable hsr_proceedBtn: boolean = false;

  //other rules
  @observable or_1: string = "";
  @observable or_2: string = "";
  @observable or_proceedBtn: boolean = false;

  @observable hidePrintContent: boolean = true;

  @observable axiosResponse: AxiosResponse | null = null;
  @observable errors: string = "";

  @observable formIsValid: boolean = false;

  @observable openVisitorCodeOption: boolean = false;
  @observable visitorName: string = "";
  @observable openVisitorCodeOptionRegular: boolean = false;

  @observable disableFindMeBtm: boolean = false;
  @observable unplannedProceedBtn: boolean = true;

  @observable temInstructionsFlag1: boolean = true;
  @observable temInstructions: boolean = false;
  @observable findMeFlag: boolean = false;

  //open instructions window
  @action openTempInstructionWin = async () => {
    runInAction(() => (this.temInstructions = true));
  };
  @action closeTemWindow = async () => {
    runInAction(() => {
      this.temInstructions = false;
      this.temInstructionsFlag1 = false;
    });
  };
  @action openVisitorCodeOptionModal = async (e: any, d: any) => {
    switch (d.name) {
      case "planned":
        this.openVisitorCodeOption = true;
        break;
      case "regular":
        this.openVisitorCodeOptionRegular = true;
        break;
      default:
        break;
    }
  };

  @action hasReference = async (option: string) => {
    switch (option) {
      case "yes":
        this.openVisitorCodeOption = false;
        history.push(
          `/planned-visit/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
        break;
      case "no":
        this.openVisitorCodeOption = false;
        history.push(
          `/unplanned-visit/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );

        break;
      default:
        break;
    }
  };
  @action hasReferenceRegular = async (option: string) => {
    switch (option) {
      case "yes":
        this.openVisitorCodeOptionRegular = false;
        history.push(
          `/regular-visitor/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
        break;
      case "no":
        this.openVisitorCodeOptionRegular = false;
        history.push(
          `/unplanned-visit/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
        break;
      default:
        break;
    }
  };
  // Other RULES
  @action onchangeOtherRules = async (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    switch (data.name) {
      case "or_1":
        this.or_1 = data.value as string;

        break;
      case "or_2":
        this.or_2 = data.value as string;

        break;
      default:
        break;
    }
    runInAction(() => {
      this.or_proceedBtn = this.or_1.length > 0 && this.or_2.length > 0;
    });
  };
  // HEALTH AND SAFETY
  @action onchangeHealthSafetyRules = async (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    switch (data.name) {
      case "hsr_1":
        this.hsr_1 = data.value as string;

        break;
      case "hsr_2":
        this.hsr_2 = data.value as string;

        break;
      case "hsr_3":
        this.hsr_3 = data.value as string;

        break;
      case "hsr_4":
        this.hsr_4 = data.value as string;

        break;
      case "hsr_5":
        this.hsr_5 = data.value as string;

        break;
      case "hsr_6":
        this.hsr_6 = data.value as string;

        break;
      case "hr_5":
        this.hr_5 = true;
        break;
      case "or_1":
        this.or_1 = data.value as string;
        break;
      case "or_2":
        this.or_2 = data.value as string;
        break;

      default:
        console.log("Unknown Option!");
        break;
    }

    this.hsr_proceedBtn =
      this.hsr_1.length > 0 &&
      this.hsr_2.length > 0 &&
      this.hsr_3.length > 0 &&
      this.hsr_4.length > 0 &&
      this.hr_4 &&
      this.hr_5 &&
      this.hsr_6.length > 0 &&
      this.or_1.length > 0 &&
      this.or_2.length > 0;
  };
  // SECURITY RULES
  @action onchangeSecurityRules = async (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    switch (data.name) {
      case "sr_1":
        this.sr_1 = true;

        break;
      case "sr_2":
        this.sr_2 = data.value as string;

        break;
      default:
        break;
    }

    this.sr_proceedBtn = this.sr_1 && this.sr_2.length > 0;
  };
  // house rules
  @action onchangeHouseRules = async (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    switch (data.name) {
      case "hr_1":
        this.hr_1 = true;

        break;
      case "hr_2":
        this.hr_2 = true;

        break;
      case "hr_3":
        this.hr_3 = true;

        break;
      case "hr_4":
        this.hr_4 = true;

        break;
      case "hr_5":
        this.hr_5 = true;
        break;
      case "sr_1":
        this.sr_1 = true;

        break;
      case "sr_2":
        this.sr_2 = data.value as string;

        break;

      default:
        break;
    }

    this.hr_proceedBtn =
      this.hr_1 &&
      this.hr_2 &&
      this.hr_3 &&
      this.hsr_5.length > 0 &&
      this.sr_1 &&
      this.sr_2.length > 0;
  };
  @action GenerateRequiredCharactersForPassCode = async () => {
    var numArr: number[] = [];
    while (numArr.length < 3) {
      var r: number = Math.floor(Math.random() * 6) + 1;
      if (!numArr.includes(r)) numArr.push(r);
    }

    //sort the num array
    numArr.sort();
    switch (numArr[0]) {
      case 1:
        this.passCode_char_1_index = 1 + "st";

        break;
      case 2:
        this.passCode_char_1_index = 2 + "nd";

        break;
      case 3:
        this.passCode_char_1_index = 3 + "rd";

        break;
      default:
        this.passCode_char_1_index = numArr[0] + "th";

        break;
    }

    switch (numArr[1]) {
      case 1:
        this.passCode_char_2_index = 1 + "st";

        break;
      case 2:
        this.passCode_char_2_index = 2 + "nd";

        break;
      case 3:
        this.passCode_char_2_index = 3 + "rd";

        break;
      default:
        this.passCode_char_2_index = numArr[1] + "th";

        break;
    }

    switch (numArr[2]) {
      case 1:
        this.passCode_char_3_index = 1 + "st";

        break;
      case 2:
        this.passCode_char_3_index = 2 + "nd";

        break;
      case 3:
        this.passCode_char_3_index = 3 + "rd";

        break;
      default:
        this.passCode_char_3_index = numArr[2] + "th";

        break;
    }

    this._GlobalPassCodeArr = numArr;
  };
  // load reason for visit
  @action LoadReasonForVisit = async () => {
    try {
      const response = await agent.visitReason.getVReasons();
      if (response) this.vReasonList = response;
    } catch (error) {
      throw error;
    }
  };
  @action CheckboxChange = async (
    event: SyntheticEvent,
    data: CheckboxProps
  ) => {
    switch (data.name) {
      case "q1":
        this.one_ans = data.value as string;

        break;
      case "q2":
        this.two_ans = data.value as string;

        break;
      case "q3":
        this.three_ans = data.value as string;

        break;
      case "q4":
        this.four_ans = data.value as string;

        break;
      case "q5":
        this.five_ans = data.value as string;

        break;
      case "q6":
        this.six_ans = data.value as string;

        break;
      default:
        console.log("[ERROR]: CheckboxChange");
        break;
    }
  };
  @action ChangeReasonForVisit = async (reason: string) => {
    this.reason = reason;

    this.unplannedValidateForm();
  };
  @action onChangeSwitch = async (field: string, value: string) => {
    switch (field) {
      case "partnerTemp":
        this.temperature = value;
        break;
      case "fName":
        this.firstName =
          (value ? value[0].toUpperCase() : value) + value.slice(1);
        break;
      case "lName":
        this.lastName =
          (value ? value[0].toUpperCase() : value) + value.slice(1);
        break;
      case "email":
        this.email = value;
        break;
      case "company":
        this.company =
          (value ? value[0].toUpperCase() : value) + value.slice(1);
        break;
      case "carReg":
        this.carReg = value.toUpperCase();
        this.highlightInputBorderCarReg = false;
        break;
      case "escort":
        this.escort = (value ? value[0].toUpperCase() : value) + value.slice(1);
        break;
      case "meetingWith":
        this.meetingWith =
          (value ? value[0].toUpperCase() : value) + value.slice(1);
        break;
      case "department":
        this.department = value.toUpperCase();
        break;
      case "meetingRefOrBadgeNum":
        this.meetingRefOrBadgeNum = value.toUpperCase();
        break;
      case "regVisitorRef":
        this.regVisitorRef = value.toUpperCase();
        break;
      case "badgeNum":
        this.depotBadgeNum = value;
        break;
      case "passCode_val1":
        this.passCode_char_1_val = value;
        break;
      case "passCode_val2":
        this.passCode_char_2_val = value;
        break;
      case "passCode_val3":
        this.passCode_char_3_val = value;
        break;
      case "temperature":
        this.temperature = value;
        this.highlightInputBorderTemp = false;

        break;
      default:
        console.log("select a field in the form");
    }

    this.validateForm();
    this.unplannedValidateForm();
  };

  @action SearchVisitByBadgeNum = async (
    tenantName: string,
    depotCode: string
  ) => {
    try {
      const visitor = await agents.visitor.searchByMeetingRefOrBadgeID({
        MeetingRef: this.meetingRefOrBadgeNum,
        tenantName: tenantName,
        depotCode: depotCode,
      });

      if (visitor) {
        this.visitor = visitor;
        this.firstName = visitor.firstName;
        this.lastName = visitor.lastName;
        this.email = visitor.email;
        this.company = visitor.company;
        this.department = visitor.host.department;
        this.meetingWith = visitor.host.name;
        this.highlightInputBorderCarReg = true;
        this.highlightInputBorderTemp = true;
      }
    } catch (error) {
      this.clearVisitorData();
      this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      setTimeout(this.ClearErrorMessage, 3000);
    }
  };
  @action SearchRegularVisitorByEmailAndPassCode = async (
    tenantName: string,
    depotCode: string
  ) => {
    try {
      const passCode: IPassCode = {
        PosOne: this._GlobalPassCodeArr[0].toString(),
        PosTwo: this._GlobalPassCodeArr[1].toString(),
        PosThree: this._GlobalPassCodeArr[2].toString(),
        CharPosOne: this.passCode_char_1_val,
        CharPosTwo: this.passCode_char_2_val,
        CharPosThree: this.passCode_char_3_val,
      };

      const visitor = await agents.visitor.searchByMeetingRefOrBadgeID({
        MeetingRef: this.regVisitorRef,
        PassCode: passCode,
        tenantName: tenantName,
        depotCode: depotCode,
      });

      if (visitor) {
        this.visitor = visitor;
        this.firstName = visitor.firstName;
        this.lastName = visitor.lastName;
        this.email = visitor.email;
        this.company = visitor.company;
        this.department = visitor.host.department;
        this.meetingWith = visitor.host.name;
        this.highlightInputBorderCarReg = true;
        this.highlightInputBorderTemp = true;
        this.findMeFlag = true;
      }
    } catch (error) {
      this.clearVisitorData();
      this.GenerateRequiredCharactersForPassCode();
      this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      history.push(
        `/regular-visitor/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
      );
      setTimeout(this.ClearErrorMessage, 3000);
    }
  };

  @action hideVirtualKeyboardVisible = async (ScreenSize: number) => {
    if (ScreenSize < 750) this.virtualKeyboardVisible = false;
  };
  @action clearVisitorData = async () => {
    try {
      this.unplannedProceedBtn = true;
      this.meetingRefOrBadgeNum = "";
      this.visitor = null;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.company = "";
      this.department = "";
      this.meetingWith = "";
      this.escort = "";
      this.carReg = "";
      this.passCode_char_1_index = "";
      this.passCode_char_2_index = "";
      this.passCode_char_3_index = "";
      this.passCode_char_1_val = "";
      this.passCode_char_2_val = "";
      this.passCode_char_3_val = "";
      this.depotBadgeNum = "";
      this.hr_1 = false;
      this.hr_2 = false;
      this.hr_3 = false;
      this.hr_4 = false;
      this.hr_5 = false;
      this.hr_proceedBtn = false;
      this.houseRulesWindow = false;
      this.sr_1 = false;
      this.sr_2 = "";
      this.sr_proceedBtn = false;
      this.securityRulesWindow = false;
      this.hsr_1 = "";
      this.hsr_2 = "";
      this.hsr_3 = "";
      this.hsr_4 = "";
      this.hsr_5 = "";
      this.hsr_6 = "";
      this.hsr_proceedBtn = false;
      this.healthSafetyWindow = false;
      this.or_1 = "";
      this.or_2 = "";
      this.or_proceedBtn = false;
      this.otherRulesWindow = false;
      this.openVisitorCodeOptionRegular = false;
      this.openVisitorCodeOption = false;
      this.reason = "";
      this.temperature = "";
      this.regVisitorRef = "";
      this.highlightInputBorderCarReg = false;
      this.highlightInputBorderTemp = false;
      this.temInstructionsFlag1 = true;
      this.findMeFlag = false;
    } catch (error) {
      throw error;
    }
  };
  @action VisitorCheckIn_plannedVisit = async () => {
    try {
      const data: IVisitorModel = {
        carReg: this.carReg,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        meetingWith: this.meetingWith,
        visitorRef: this.meetingRefOrBadgeNum,
        depotBadgeNum: this.depotBadgeNum,
        depot: this.rootStore.depotStore.depot?.id!,
        temperature: this.temperature,
      };

      const response = await agent.visitor.checkIn(data);
      if (response) {
        this.ShowSignNotice();
        history.push(
          `/print-house-rules/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
      } else {
        // do something else
      }
    } catch (error) {
      this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      setTimeout(this.ClearErrorMessage, 3000);
    }
  };
  @action VisitorCheckIn_unplannedVisit = async () => {
    try {
      const data: IVisitorModel = {
        carReg: this.carReg,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        meetingWith: this.meetingWith,
        depotBadgeNum: this.depotBadgeNum,
        reasonForVisit: this.reason,
        Company: this.company,
        depot: this.rootStore.depotStore.depot?.id!,
        temperature: this.temperature,
      };

      const response = await agent.visitor.unplannedVisitCheckIn(data);
      if (response) {
        this.ShowSignNotice();
        history.push(
          `/print-house-rules/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
      } else {
        // do something else
      }
    } catch (error) {
      this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      setTimeout(this.ClearErrorMessage, 3000);
    }
  };
  @action VisitorCheckIn_regularVisitor = async () => {
    try {
      const data: IVisitorModel = {
        carReg: this.carReg,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        meetingWith: this.meetingWith,
        visitorRef: this.regVisitorRef,
        depotBadgeNum: this.depotBadgeNum,
        depot: this.rootStore.depotStore.depot?.id!,
        temperature: this.temperature,
      };

      const response = await agent.visitor.checkIn(data);
      if (response) {
        history.push(
          `/print-house-rules/${this.rootStore.depotStore.depot?.tenantName}/${
            this.rootStore.depotStore.depot?.code
          }/${"regular"}`
        );
      } else {
        // do something else
      }
    } catch (error) {
      this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;
      setTimeout(this.ClearErrorMessage, 3000);
    }
  };
  @action OpenConditionsWindow = async (visitType: string) => {
    try {
      this.visitPath = history.location.pathname;
      switch (visitType) {
        case "unplanned-visitor":
          await this.UnplannedVisitHostNotification(this.reason);
          break;
        case "regular-visitor":
          await this.RegularVisitHostNotification(this.regVisitorRef);
          break;
        case "planned-visitor":
          await this.PlannedVisitHostNotification(this.meetingRefOrBadgeNum);
          break;
        default:
          break;
      }
      if (visitType !== "regular-visitor") this.houseRulesWindow = true;
      else {
        history.push(
          `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };


  @action PlannedVisitHostNotification = async (meetingRef: string) => {
    let data: IEmailNotification = {
      depot: this.rootStore.depotStore.depot?.id!,
      meetingRef: meetingRef,
      tenant: this.rootStore.depotStore.depot?.tenantName,
    };
    try {
      await agent.visitor.sendEmail_plannedVisit(data);
    } catch (error) {
      throw error;
    }
  };

@action RegularVisitHostNotification = async (meetingRef: string) => {
    let data: IEmailNotification = {
      depot: this.rootStore.depotStore.depot?.id!,
      meetingRef: meetingRef,
      tenant: this.rootStore.depotStore.depot?.tenantName,
    };
    try {
      await agent.visitor.sendEmail_regularVisit(data);
    } catch (error) {
      throw error;
    }
  };


  @action UnplannedVisitHostNotification = async (reason: string) => {
    let data: IEmailNotification = {
      depot: this.rootStore.depotStore.depot?.id!,
      reason: reason,
      name: this.firstName + " " + this.lastName,
      company: this.company,
    };
    try {
      await agent.visitor.sendEmail_unplannedVisit(data);
    } catch (error) {
      throw error;
    }
  };



  @action DennyConditions = async () => {
    this.houseRulesWindow = false;
    this.securityRulesWindow = false;
    this.otherRulesWindow = false;
    this.healthSafetyWindow = false;
  };
  @action VisitorSubmitBadgeNumber = async () => {
    if (this.visitPath.includes("/unplanned-visit/"))
      await this.VisitorCheckIn_unplannedVisit();
    if (this.visitPath.includes("/planned-visit/"))
      await this.VisitorCheckIn_plannedVisit();
    if (this.visitPath.includes("/regular-visitor/"))
      await this.VisitorCheckIn_regularVisitor();
   
  };

  @action AcceptConditions = async (name: string) => {
    switch (name) {
      case "houseRules":
        this.houseRulesWindow = false;
        // this.securityRulesWindow = true;
        //this.otherRulesWindow = false;
        this.healthSafetyWindow = true;
        break;
      // case "SecurityRules":
      //   runInAction(() => {
      //     this.houseRulesWindow = false;
      //     this.securityRulesWindow = false;
      //     this.healthSafetyWindow = true;
      //     this.otherRulesWindow = false;
      //   });
      //   break;
      case "HeathSafetyRules":
        this.houseRulesWindow = false;
        // this.securityRulesWindow = false;
        // this.otherRulesWindow = false;
        this.healthSafetyWindow = false;

        //   break;
        // case "OtherRules":
        //   runInAction(() => {
        //     this.houseRulesWindow = false;
        //     this.securityRulesWindow = false;
        //     this.otherRulesWindow = false;
        //     this.healthSafetyWindow = false;
        //   });
        history.push(
          `/enter-badge-number/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
        break;
      default:
        break;
    }
  };
  @action ManualCheckOut = async (
    depotBadge: string,
    depotId: string,
    visitorId: string,
    sendNotification: boolean,
    tenantId?: string
  ) => {
    try {

      const response = await agent.visitor.checkout({
        visitorAttendanceId: visitorId,
        manualCheckout: true,
        depotBadge: depotBadge,
        sendNotification: sendNotification,
        depotId: depotId,
      });

      if (response) {
        toast.success("Visitor was checked-out");
        window.location.reload(false);
        history.push("/reports");
        if (tenantId)
          this.rootStore.tenantStore.findTenant({ tenantId: tenantId });
        this.rootStore.modalStore.closeModal();
        //history.push("/dashboard");
      }
    } catch (error) {
      throw error;
    }
  };

  @action VisitorCheckOut = async (badgeNum: string, depotId: string) => {
    try {
      const visitor = await agent.visitor.getVisitorName(badgeNum, depotId);

      if (visitor) {
        this.visitorName = visitor.name;
        const response = await agent.visitor.checkout({
          visitorAttendanceId: visitor.id,
          manualCheckout: false,
          depotId: depotId,
          depotBadge: badgeNum,
        });
        if (response) {
          this.checkoutWindow = true;

          setTimeout(this.closeCheckoutMessage, 4000);
        } else {
          // do something else
        }
      }
    } catch (error) {
      this.axiosResponse = error;
      this.errors = this.axiosResponse?.request.response;

      setTimeout(this.ClearErrorMessage, 3000);
    }
  };
  @action closeCheckoutMessage = () => {
    this.checkoutWindow = false;

    if (!this.checkoutWindow) {
      this.clearVisitorData();
      history.push(
        `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
      );
    }
  };
  @action closeCheckInMessage = () => {
    this.checkInWindow = false;

    if (!this.checkInWindow) {
      this.clearVisitorData();
      history.push(
        `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
      );
    }
  };

  @action ClearErrorMessage = async () => {
    this.errors = "";
  };
  @action ChangeEnterBadgeNum = async (depotBadgeNum: string) => {
    this.depotBadgeNum = depotBadgeNum;
  };

  @action PrintHouseRulesClick = async (signBlob: any) => {
    const response = await agents.submitSign.formSignature({
      sign: signBlob,
      depotBadge: this.depotBadgeNum,
      depot: this.rootStore.depotStore.depot?.id!,
    });

    this.checkInWindow = true;
    if (response) {
      setTimeout(this.closeCheckInMessage, 4000);
      if (!this.checkInWindow)
        history.push(
          `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
        );
    }
  };

  @action ShowSignNotice = async () => {
    this.scrollDownFlag = true;

    setTimeout(this.CloseSignNotice, 3000);
    if (!this.checkInWindow)
      history.push(
        `/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
      );
  };
  @action CloseSignNotice = async () => {
    this.scrollDownFlag = false;
  };
  @action CloseModalHR = async () => {
    this.houseRulesWindow = false;
  };
  @action CloseModalSR = async () => {
    this.securityRulesWindow = false;
  };
  @action CloseModalHSR = async () => {
    this.healthSafetyWindow = false;
  };
  @action CloseModalOR = async () => {
    this.otherRulesWindow = false;
  };
  @action refreshPage = async () => {
    history.push(
      `/print-house-rules/${this.rootStore.depotStore.depot?.tenantName}/${this.rootStore.depotStore.depot?.code}`
    );
  };
  @action validateForm = async () => {
    if (
      this.firstName.length <= 0 &&
      this.lastName.length <= 0 &&
      this.meetingWith.length <= 0 &&
      this.email.length <= 0 &&
      this.company.length <= 0 &&
      this.temperature.length <= 0
    )
      this.formIsValid = false;
    else this.formIsValid = true;

    this.disableFindMeBtm =
      this.passCode_char_1_val.length === 1 &&
      this.passCode_char_2_val.length === 1 &&
      this.passCode_char_3_val.length === 1 &&
      this.regVisitorRef.length > 0;
  };
  @action unplannedValidateForm = async () => {
    try {
      if (
        this.temperature.length > 0 &&
        this.firstName.length > 0 &&
        this.lastName.length > 0 &&
        this.email.length > 0 &&
        this.company.length > 0 &&
        this.carReg.length > 0 &&
        this.reason.length > 0
      )
        this.unplannedProceedBtn = false;
    } catch (error) {
      throw error;
    }
  };
}
