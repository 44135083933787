import React, { useContext, useRef, useState } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { Grid, Table, Button } from "semantic-ui-react";
import { Container } from "reactstrap";
import SignatureCanvas from "react-signature-canvas";
import { observer } from "mobx-react-lite";
import { format} from 'date-fns'

const PrintPartnerHouseRules = (depot) => {
  const rootStore = useContext(RootStoreContext);

  const {
    partnerData,
    PartnerPrintHouseRulesClick,
  } = rootStore.partnerStore;

  let canvasRef = useRef(null);

  const [disabled, SetDisabled] = useState(true);
  const isEmpty = () => SetDisabled(canvasRef.isEmpty());
  const save = () =>  PartnerPrintHouseRulesClick( dataURItoBlob(canvasRef.getCanvas().toDataURL("image/png", 1)));

  // const { match } = props;
  // let { depotName } = match.params;
// const [date, setDate] = useState(Date.now())


  // useEffect(() => {
  //   let isMounted = true;
  //   if (!depot) {
  //     getDepot(depotName);
  //   }
  //   return () => {
  //       isMounted = false;
  //   };
  // }, [depotName, getDepot, depot, canvasRef]);

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  return (
    <Container>
      <div className="printPage">
        <h2 style={{ textAlign: "center" }}>
          Welcome, {partnerData?.name}, from {partnerData?.organisation}
        </h2>

        <Grid centered>
          <br />
          <br />
          <h2>Please, see below the House Rules you have agreed to</h2>

          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  When in the Depot - you will always wear a Protective Personal
                  Equipment.
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  You should not operate any PPE equipment, unless you are
                  trained to do so.
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  You will adhere to all the food hygiene, Covid-19 and security
                  protocols that Masterlink have in place.
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  Use of your personal mobile phone is not allowed in the Depot.
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  Personal belongings (such as bag) must be left in the Locker
                  Room or Canteen.
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  In the last 14 days you have not had any symptoms of Covid-19
                  (such as, Fiver, High Temperature, Sore Throat, Runny Nose,
                  Breathless, etc)?
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  In the last 14 you have not traveled no any Country that is
                  not on the Government Travel Green List.
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  I understand that there is a Search Policy in place and I
                  agree to accept same.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid>
        <br />
        <br />

        <Grid>
          <Grid.Column width={10}>
            <strong>Please sign here to confirm.</strong>
            <br />
            <div style={{ backgroundColor: "#f3f3f3" }}>
              <SignatureCanvas
                penColor="black"
                onBegin={isEmpty}
                onEnd={isEmpty}
                ref={(c) => (canvasRef = c)}
                canvasProps={{
                  width: 650,
                  height: 160,
                  className: "sigCanvas",
                }}
              />
            </div>
          </Grid.Column>
          <Grid.Column width={5}>
            <div style={{ paddingTop: 95, marginTop: 70, fontSize: 16 }}>
            {format(new Date(), "dd:MM:yyyy - HH:mm")}
            </div>
          </Grid.Column>

          <Grid.Column width={6}>
            <Button
              onClick={() => canvasRef.clear() & SetDisabled(true)}
              icon="delete"
              fluid
              color={"red"}
              content="Clear Signature"
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <Button
              onClick={save}
              icon="save"
              color={"green"}
              fluid
              disabled={disabled}
              content="Complete"
            />
          </Grid.Column>
        </Grid>
      </div>
    </Container>
  );
};

export default observer(PrintPartnerHouseRules);
