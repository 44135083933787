import React from "react";
import { Modal, Button, Image, Divider,  Grid } from "semantic-ui-react";
import "../../style/modal.css";
import tempInstructions from "../../assets/tempInstructions.jpeg";
import staticTem from "../../assets/staticTem.jpeg";


interface IProps {
    open: boolean;
    accept: () => void;
}
const TemperatureInstruction: React.FC<IProps> = ({ open, accept }) => {
    return (
      <Modal open={open} size="small" onClose={accept} className="temp-modal">
        <Modal.Header>Temperature Instructions</Modal.Header>

        <Modal.Content>
          <Grid columns="3">
            <Grid.Column className='imgColumn'>
              <h2>
                <u>
                  Please use the sanitizer before using the handheld
                  thermometer!
                </u>
              </h2>
              <Image centered src={tempInstructions} />
            </Grid.Column>
      <Grid.Column width='1'>
     <Divider content='Or' vertical/>
      </Grid.Column>
      <Grid.Column className='imgColumn'>
              <h2>
                <u>
                Place your forehead 5 to 10 centimeters away from the
                  hands free thermometer!
                </u>
              </h2>
              <Image centered src={staticTem} />
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={accept}>
            Continue
          </Button>
        </Modal.Actions>
      </Modal>
    );
};

export default TemperatureInstruction;