import React, { useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { Icon, Header } from "semantic-ui-react";
import {RootStoreContext} from '../../stores/rootStore'
 

interface IProps {
  setFiles: (files: object[]) => void;
}

const dropzoneStyles = {
  border: "dashed 3px",
  borderColor: "#eee",
  borderRadius: "5px",
  paddingTop: "30px",
  textAlign: "center" as "center",
  height: "200px"
};

const dropzoneActive = {
  borderColor: "green"
};

const FileWidgetDropZone: React.FC<IProps> = ({ setFiles }) => {
  const rootStore = useContext(RootStoreContext);
  const { setPartnerFile } = rootStore.partnerStore;

  const onDrop = useCallback(
    (acceptedFiles) => {
      setPartnerFile(acceptedFiles[0]);

      setFiles(
        acceptedFiles.map((file: object) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [setPartnerFile, setFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  return (
    <div
      {...getRootProps()}
      style={
        isDragActive ? { ...dropzoneStyles, ...dropzoneActive } : dropzoneStyles
      }
    >
      <input {...getInputProps()} />
      <Icon name="upload" size="huge" />
      <Header content="Select/ Drop File Here" />
    </div>
  );
};

export default FileWidgetDropZone;
