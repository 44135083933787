import React from "react";
import { Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
interface IProps {
    open: boolean;
    depotBadgeNum: string;
}
const CheckInMessage: React.FC<IProps> = ({ open, depotBadgeNum }) => {
    return (
      <div>
        <Modal
          dimmer="inverted"
          style={{
            height: 307,
            width: 500,
            left: "auto",
            right: "auto",
            marginTop: 40,
          }}
          open={open}
          closeIcon
          closeOnDimmerClick
          closeOnEscape
        >
          <Modal.Header>Checked-In</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <h1>You have Checked In Successfully!</h1>
              <h2>Enjoy Your Visit!</h2>
              <hr />
              <div className='checkInMessage'>
                <p>
                  Please check with your Host and keep the Badge
                  <strong>{depotBadgeNum}</strong> with you during your visit.
                </p>
                <p>
                  Please <strong>return</strong> your badge at the end of your
                  visit!
                </p>
              </div>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
};

export default observer(CheckInMessage);