import React, { useContext, useEffect } from "react";
 import { combineValidators, isRequired, matchesField } from "revalidate";
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Checkbox,
  Divider,
} from "semantic-ui-react";
import { Field, Form as FinalForm } from "react-final-form";
import TextInput from "../../../common/form/TextInput";
import { RootStoreContext } from "../../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { FORM_ERROR } from "final-form";
import { IBookVisitFormData } from "../../../models/bookVisitModel";
import SelectInput from "../../../common/form/SelectInput";
import ManageMeetingLayout from "../../layout/ManageMeetingLayout";


 
const validate = combineValidators({
  AttendeeFirstName: isRequired("Attendee First Name")  ,
  AttendeeLastName: isRequired("Attendee Last Name") ,
  AttendeeEmail: isRequired("Attendee Email") ,
  AttendeeCompany: isRequired("Attendee Company") ,
  passCode: isRequired("Pass Code"),
   
  repeatPassCode: matchesField(
    "passCode",
    "repeatPassCode"
  )({ message: "Pass Codes do not match" }),
});

const BookMeeting = () => {
  const rootStore = useContext(RootStoreContext);
  const {  depots } = rootStore.depotStore;
  const {
    BookMeeting,
    regVisitorRef,
    hostFlag,
    changeHostFlag,
    getRegVisitorRef,
    changeRegularVisitorFlag,
    printLabelFlag,
    ChangePrintLabelFlag,
  } = rootStore.meetingStore;
  const { user } = rootStore.userStore;

  useEffect(() => {
    //ListDepot();
    
    getRegVisitorRef();
    changeRegularVisitorFlag();
    return () => {
      changeRegularVisitorFlag();
    };
  }, [ getRegVisitorRef, changeRegularVisitorFlag]);

  return (
    <ManageMeetingLayout>
      <br />
      <br />
      <br />
      <Grid centered>
        <Grid.Column width={15}>
          <Header as="h2" style={{ borderWidth: 0 }} attached="top">
            Book a Visit
          </Header>

          <Segment clearing style={{ borderWidth: 0 }}>
            <FinalForm
              onSubmit={(values: IBookVisitFormData) =>
                BookMeeting(values).catch((error) => ({
                  [FORM_ERROR]: error,
                }))
              }
              validate={validate}
              render={({
                handleSubmit,
                submitting,
                submitError,
                invalid,
                pristine,
                dirtySinceLastSubmit,
              }) => (
                <Form onSubmit={handleSubmit} error loading={submitting}>
                  <Header style={{ borderWidth: 0 }} as="h3" attached="top">
                    Regular Visitor Details:
                  </Header>
                  <Divider horizontal />

                  <Form.Group widths="12">
                    <Form.Field width={4}>
                      <label>Regular Visitor Reference</label>
                      <input
                        id="regVisitorRef"
                        name="regVisitorRef"
                        type="text"
                        readOnly
                        value={regVisitorRef}
                    
                        placeholder="Regular Visitor Reference"
                      />
                    </Form.Field>

                    <Field
                      label="Pass Code"
                      name="passCode"
                      placeholder="Pass Code"
                      type="password"
                      maxLength={6}
                      minLength={6}
                      component={TextInput}
                    />
            
                    <Field
                      label="Repeat Pass Code"
                      type="password"
                      maxLength={6}
                      minLength={6}
                      name="repeatPassCode"
                      placeholder="Repeat Pass Code"
                      component={TextInput}
                    />
                  </Form.Group>

                  <Header style={{ borderWidth: 0 }} as="h3" attached="top">
                    Attendee Details:
                  </Header>
                  <Form.Group widths="equal">
                    <Field
                      label="First Name"
                      name="AttendeeFirstName"
                      placeholder="Attendee First Name"
                      component={TextInput}
                    />
                    <Field
                      label="Last Name"
                      name="AttendeeLastName"
                      placeholder="Attendee Last Name"
                      component={TextInput}
                    />

                    <Field
                      label=" Email"
                      name="AttendeeEmail"
                      placeholder="Attendee Email"
                      type="email"
                      component={TextInput}
                    />

                    <Field
                      label=" Company"
                      name="AttendeeCompany"
                      placeholder="Attendee Company"
                      component={TextInput}
                    />
                  </Form.Group>

                  <Header style={{ borderWidth: 0 }} as="h3" attached="top">
                    Regular Host Details:
                  </Header>

                  <Form.Group widths="equal">
                    <Checkbox
                      label="I'm the host. "
                      checked={hostFlag}
                      onClick={changeHostFlag}
                    />
                  </Form.Group>
                  {!hostFlag && (
                    <Form.Group widths="equal">
                      <Field
                        label="Department"
                        name="department"
                        placeholder="Department"
                        component={TextInput}
                      />
                      <Field
                        label="Depot"
                        name="depot"
                        options={depots}
                        placeholder="Depot"
                        component={SelectInput}
                      />

                      <Field
                        readOnly={hostFlag}
                        label="Name"
                        name="hostName"
                        placeholder="Host Name"
                        component={TextInput}
                      />

                      <Field
                        value={hostFlag ? user?.email : ""}
                        readOnly={hostFlag}
                        label="Email"
                        name="hostEmail"
                        placeholder="Host Email"
                        type="email"
                        component={TextInput}
                      />
                    </Form.Group>
                  )}

                  {/*
            {submitError && !dirtySinceLastSubmit && (
              <RegisterErrorMessage error={submitError} />
           )}
            */}
                  <Form.Group>
                    <Checkbox
                      label="Print Label "
                      checked={printLabelFlag}
                      onClick={ChangePrintLabelFlag}
                      toggle
                    />
                  </Form.Group>
                  <Button
                    icon="book"
                    loading={submitting}
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                    positive
                    content="Register"
                  />
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
      </ManageMeetingLayout>
  );
};

export default observer(BookMeeting);
