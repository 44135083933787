import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthorizeRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        localStorage.getItem('jwt') !== null
            ? <Component {...props} />
            : <Redirect to='/ml-admin' />
    )} />
)

export default AuthorizeRoute;