import React, { useContext } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import {
  Modal,
  Table,
  Checkbox,
  Button,
  List,
  Form,
  CheckboxProps,
  Divider,
} from "semantic-ui-react";
interface IProps {
  open: boolean;
  accept: (name: string) => void;
  denny: () => void;
}

const SecurityModal: React.FC<IProps> = ({ open, accept, denny }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    sr_1,
    sr_2,
    sr_proceedBtn,
    CloseModalSR,
    onchangeSecurityRules,
  } = rootStore.visitorStore;
  return (
    <Modal
      style={{
        height: 445,
        width: 960,
        left: "auto",
        right: "auto",
        marginTop: 40,
        fontSize: 16,
      }}
      // style={{ height: 404, left: "auto", right: "auto", marginTop: 40 }}
      open={open}
      closeOnDimmerClick
      onClose={CloseModalSR}
      closeOnEscape
      size="small"
    >
      <Modal.Header>Security</Modal.Header>
      <Modal.Content>
        <Table striped>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                During your visit security must be observed at all times, please
                acknowledge the following:
                <Divider horizontal />
                <List as="ul" style={{ marginLeft: 80 }}>
                  <List.Item as="li">
                    From the moment you arrive, you are under CCTV surveillance{" "}
                  </List.Item>
                  <List.Item as="li">
                    All entrance and exit points are guarded with physical
                    access control
                  </List.Item>
                  <List.Item as="li">
                    Do not be offended if challenged, staff members are trained
                    to do this
                  </List.Item>
                </List>
                <Divider horizontal />
              </Table.Cell>
              <Table.Cell>
                <Checkbox
                  label="Accept"
                  name="sr_1"
                  checked={sr_1}
                  onChange={(
                    event: React.FormEvent<HTMLInputElement>,
                    data: CheckboxProps
                  ) => onchangeSecurityRules(event, data)}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Please do not force or enter any area without your escort. When
                entering the Operations area, you must remain with your escort
                at all times.
              </Table.Cell>
              <Table.Cell>
                <Form.Group>
                  <Checkbox
                    label="Accept"
                    name="sr_2"
                    value="yes"
                    checked={sr_2 === "yes"}
                    onChange={(
                      event: React.FormEvent<HTMLInputElement>,
                      data: CheckboxProps
                    ) => onchangeSecurityRules(event, data)}
                  />
                </Form.Group>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <div className="modalPageNum">
          <p> Page 2 of 4</p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={!sr_proceedBtn}
          positive
          onClick={() => accept("SecurityRules")}
        >
          Proceed
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default observer(SecurityModal);
