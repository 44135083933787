import React, { useContext } from "react";
import { Container } from "reactstrap";
import { observer } from "mobx-react-lite";
import { Grid, Image } from "semantic-ui-react";
import logo from "../../assets/visIT_large_logo.png";
import NavBar from "./NavBar";
import { RootStoreContext } from "../../stores/rootStore";
import { Redirect } from "react-router-dom";

interface IProps {
  children: any;
}

const AnonymousLayout: React.FC<IProps> = ({ children }) => {

  const rootStore = useContext(RootStoreContext);
  const {isLoggedIn} = rootStore.userStore;
  // if (isLoggedIn) 
  // return <Redirect to="/dashboard" />;
  // else
  return (
    <div>
      <NavBar backgroundColor={"#e4cf36"} height={35} />

      <div className="visit-background">
        <Container>
          <Grid centered>
            <Grid.Row>
              <Image src={logo} size="big" />
            </Grid.Row>
            {children}
          </Grid>
        </Container>
      </div>
    </div>
  );
};
export default observer(AnonymousLayout);
