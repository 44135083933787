import React, { useContext } from "react";
import { FieldRenderProps } from "react-final-form";
import {
  FormFieldProps,
  Form,
  Label,
  Select,
  DropdownProps,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/rootStore";

interface IProps extends FieldRenderProps<any, HTMLElement>, FormFieldProps {}

const SelectInput: React.FC<IProps> = ({
  input,
  label,
  width,
  options,
  placeholder,
  componentFlag,
  loading,
  disabled,
  meta: { touched, error },
}) => {
  const rootStore = useContext(RootStoreContext);
  const { ListBookedVisitors, ListPartners } = rootStore.reportStore;
  const { setTenantId, resetDepotList } = rootStore.depotStore;
  const {setDepotSelected} = rootStore.partnerStore

  const { user } = rootStore.userStore;
  const handleChange = (data: DropdownProps) => {
    // need to select the tenant id
    if (componentFlag === "report") {
      setTenantId(data?.value as string);
      resetDepotList();
    }
    if (componentFlag === "viewBookedVisits") {
      ListBookedVisitors(user?.tenantId!, data.value as string);
    }
    if (componentFlag === "viewRegisteredPartners") {
      setDepotSelected(data.value as string)
      ListPartners(user?.tenantId!, data.value as string);
    }

    input.onChange(data.value);
  };

  return (
    <Form.Field error={touched && !!error} width={width}>
      <label>{label}</label>
      <Select
        // disabled={disabled}
        loading={loading}
        value={input.value}
        onChange={(e, data) => handleChange(data)}
        placeholder={placeholder}
        options={options}
      />
      {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )}
    </Form.Field>
  );
};

export default SelectInput;
