import React, { useContext, useState, useRef, useEffect } from "react";
import NavBar from "../../components/layout/NavBar";
import { Button, Grid, Image, Form, Card } from "semantic-ui-react";
import logo from "../../assets/visIT_logo.png";
import { RootStoreContext } from "../../stores/rootStore";
import Keyboard from "react-simple-keyboard";
import { observer } from "mobx-react-lite";
import "../../style/visitor.css";
import ErrorMessage from "../../components/layout/ErrorMessage";
import PartnerHouseRules from "./PartnerHouseRules";

const PartnerTemp = (props) => {
  const rootStore = useContext(RootStoreContext);
  const {
    partnerSubmitTemperature, 
    temperature,partnerHouseRules,
    onChangeSwitch, partnerModal, partnerData,
    errors,
    axiosResponse,  
  } = rootStore.visitorStore;
  const { depot, getDepot } = rootStore.depotStore;
  const [inputName, setInputName] = useState("");
  const keyboard = useRef();
  const setActiveInput = (event) => setInputName(event.target.id);
  const [layout, setLayout] = useState("default");
  const { match } = props;
  let { tenantName, depotName} = match.params;

  useEffect(() => {
    if (!depot) {
      getDepot(tenantName, depotName);
    }
    document.getElementById("partnerTemp").focus();
  }, [depot, getDepot, tenantName, depotName]);

  const handleShift = () =>
    setLayout(layout === "default" ? "shift" : "default");

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    partnerSubmitTemperature();
  };

  const onChangeForm = (event) => {
    onChangeSwitch(event.target.name, event.target.value);
    keyboard.current.setInput(event.target.value);
  };
  const onChange = (input) => {
    onChangeSwitch(inputName, input);
  };

  return (
    <div>
      <NavBar backgroundColor={"#e4cf36"} height={35} />
 

      <PartnerHouseRules open={partnerHouseRules} />
      <Grid columns={2} centered style={{ marginTop: 5 }}>
        <Image
          src={logo}
          style={{
            marginTop: 20,
            marginBottom: 25,
          }}
        />
        <br />
      </Grid>
      <Grid centered>
        <Form onSubmit={handleSubmit} error method="post">
  
          <Grid.Row>
            <Form.Field>
              <input
                required
                style={{ fontSize: 18, borderWidth: 2 }}
                id="partnerTemp"
                name="partnerTemp"
                type="text"
                value={temperature}
                onChange={onChangeForm}
                placeholder="Temperature"
                onFocus={setActiveInput}
                autoComplete={"off"}
              />
            </Form.Field>
            <Button
              style={{ marginBottom: 15, marginLeft: 25 }}
              size="big"
              icon="sign-in"
              primary
              content="Submit"
            />
          </Grid.Row>
        </Form>

        {partnerModal && (
          <Grid.Row columns="1" centered>
            <Grid.Column width="6">
              <Card fluid color="red">
                <Card.Content>
                  <Grid columns="2">
                    <Grid.Column width="11">
                      Please Confirm your Identity: {partnerData?.name}
                    </Grid.Column>
                    <Grid.Column width="5">
                     
                    </Grid.Column>
                  </Grid>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        )}


        <br />
        <Grid.Row>
          {errors && <ErrorMessage error={axiosResponse} text={errors} />}
        </Grid.Row>
      </Grid>

      <div className="keyboardPosition">
        <Keyboard
          inputName={inputName}
          useMouseEvents={true}
          keyboardRef={(r) => (keyboard.current = r)}
          layoutName={layout}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </div>
    </div>
  );
};

export default observer(PartnerTemp);
