import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext,
} from "react";
import { Grid, Form, Button, Table, Image } from "semantic-ui-react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import logo from "../../assets/visIT_logo.png";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
// import HouseRules from "../Modals/HouseRules";
// import SecurityRules from "../Modals/SecurityModal";
// import HealthSafety from "../Modals/HealthSafety";
// import OtherRules from "../Modals/OtherRules";
import ErrorMessage from "../layout/ErrorMessage";
import { history } from "../..";
import TemperatureInstruction from "../Modals/TemperatureInstruction";

const RegularVisitor = (props) => {
  const [inputName, setInputName] = useState("");

  const [layout, setLayout] = useState("default");
  const rootStore = useContext(RootStoreContext);
  const {
    onChangeSwitch,
    regVisitorRef,
    firstName,
    OpenConditionsWindow,
    carReg,
    // DennyConditions,
    // AcceptConditions,
    lastName,
    email,
    company,
    temperature,
    meetingWith,
    GenerateRequiredCharactersForPassCode,
    SearchRegularVisitorByEmailAndPassCode,
   // healthSafetyWindow,
    findMeFlag,
    // otherRulesWindow,
    // securityRulesWindow,
    // houseRulesWindow,
    passCode_char_1_index,
    passCode_char_2_index,
    passCode_char_3_index,
    passCode_char_1_val,
    passCode_char_2_val,
    passCode_char_3_val,
    temInstructionsFlag1,
    temInstructions,
    axiosResponse,
    formIsValid,
    closeTemWindow,
    openTempInstructionWin,
    errors,
    clearVisitorData,
    disableFindMeBtm,
    highlightInputBorderCarReg,
    highlightInputBorderTemp,
  } = rootStore.visitorStore;
  const { depot, getDepot } = rootStore.depotStore;
  const keyboard = useRef();

  const setActiveInput = (event) => setInputName(event.target.id);

  const onChange = (input) => {
    onChangeSwitch(inputName, input);

    if (inputName === "passCode_val1") {
      if (input.length === 1) document.getElementById("passCode_val2").focus();
    }
    if (inputName === "passCode_val2") {
      if (input.length === 1) document.getElementById("passCode_val3").focus();
    }
    if (inputName === "passCode_val3") {
      if (input.length === 1) document.getElementById("findMe").focus();
    }
  };
  const { match } = props;
  let {tenantName, depotCode } = match.params;

  useEffect(() => {
    document.getElementById("regVisitorRef").focus();
    if (!temInstructionsFlag1) document.getElementById("temperature").focus();

    if (errors) {
      keyboard.current.clearInput("regVisitorRef");
      keyboard.current.clearInput("passCode_val2");
      keyboard.current.clearInput("passCode_val3");
      keyboard.current.clearInput("passCode_val1");
    }
    if (!depot) getDepot(tenantName, depotCode);
    
    if (firstName.length > 0 && temInstructionsFlag1)
      document.getElementById("carReg").focus();
    //validateForm();
    GenerateRequiredCharactersForPassCode();
  }, [
    //validateForm,
    errors,
    depotCode,
    getDepot,
    depot,
    firstName,
    GenerateRequiredCharactersForPassCode,
    temInstructionsFlag1,
    tenantName
  ]);

  const onChangeForm = (event) => {
    onChangeSwitch(event.target.name, event.target.value);
    keyboard.current.setInput(event.target.value);

    if (event.target.name === "passCode_val1") {
      if (event.target.value.length === 1)
        document.getElementById("passCode_val2").focus();
    }
    if (event.target.name === "passCode_val2") {
      if (event.target.value.length === 1)
        document.getElementById("passCode_val3").focus();
    }
    onChangeSwitch(event.target.name, event.target.value);
    keyboard.current.setInput(event.target.value);
  };

  const handleShift = () =>
    setLayout(layout === "default" ? "shift" : "default");

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    OpenConditionsWindow("regular-visitor");
  };

  const BackHomeClick = () => {
    clearVisitorData();
    history.push(`/${depot.tenantName}/${depot.code}`);
  };
  return (
    <Fragment>
      {/* // show temperature instruction */}
      <TemperatureInstruction
        open={temInstructions && temInstructionsFlag1}
        accept={closeTemWindow}
      />
      {/* // end instructions */}
      {/* <HouseRules
        open={houseRulesWindow}
        denny={DennyConditions}
        accept={AcceptConditions}
      />
      <SecurityRules
        open={securityRulesWindow}
        denny={DennyConditions}
        accept={AcceptConditions}
      />
      <HealthSafety
        open={healthSafetyWindow}
        denny={DennyConditions}
        accept={AcceptConditions}
      />
      <OtherRules
        open={otherRulesWindow}
        denny={DennyConditions}
        accept={AcceptConditions}
      /> */}

      <Button
        style={{ marginBottom: 15, marginLeft: 25, marginTop: 20 }}
        size="big"
        icon="home"
        onClick={BackHomeClick}
        default
        content="Home"
      />

      <Grid centered style={{ marginTop: 5 }} className="formInput">
        <Image
          src={logo}
          style={{
            marginTop: 15,
          }}
        />
        <Grid.Row width="equal" centered columns={3}>
          <Grid.Column width={5}>
            <h3>Enter Your Regular Visitor Code</h3>
            <h3>Enter the following numbers from your Personal Code</h3>
          </Grid.Column>

          <Grid.Column width={4}>
            <Form>
              <Form.Field>
                <input
                  autoComplete={"off"}
                  id="regVisitorRef"
                  name="regVisitorRef"
                  type="text"
                  value={regVisitorRef}
                  placeholder="RV Code"
                  onFocus={setActiveInput}
                  onChange={onChangeForm}
                />
              </Form.Field>

              <Form.Group>
                <Form.Field>
                  <input
                    autoComplete={"off"}
                    id="passCode_val1"
                    name="passCode_val1"
                    type="password"
                    value={passCode_char_1_val}
                    placeholder={passCode_char_1_index}
                    onFocus={setActiveInput}
                    onChange={onChangeForm}
                    maxLength={1}
                  />
                </Form.Field>
                <Form.Field>
                  <input
                    autoComplete={"off"}
                    id="passCode_val2"
                    name="passCode_val2"
                    type="password"
                    value={passCode_char_2_val}
                    placeholder={passCode_char_2_index}
                    onFocus={setActiveInput}
                    onChange={onChangeForm}
                    maxLength={1}
                  />
                </Form.Field>
                <Form.Field>
                  <input
                    autoComplete={"off"}
                    id="passCode_val3"
                    name="passCode_val3"
                    type="password"
                    value={passCode_char_3_val}
                    placeholder={passCode_char_3_index}
                    onFocus={setActiveInput}
                    onChange={onChangeForm}
                    maxLength={1}
                    minLength={1}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            <Grid.Row>
              <Button
                content="Find Me"
                color="facebook"
                fluid
                disabled={!disableFindMeBtm || findMeFlag}
                id="findMe"
                onClick={() => SearchRegularVisitorByEmailAndPassCode(tenantName, depotCode)}
              />
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>

        <Grid.Column width={11}>
          {/* <Message warning style={{fontSize: 16}}> Please <strong>Note</strong>: All fields bellow must be completed!</Message>
           */}
          <Form onSubmit={handleSubmit} error method="post">
            <Table size="large" style={{ width: "100%", border: 0 }}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Form.Field>
                      <input
                        autoComplete={"off"}
                        required
                        readOnly={!formIsValid}
                        id="fName"
                        name="fName"
                        type="text"
                        onChange={onChangeForm}
                        value={firstName}
                        placeholder="First Name"
                        onFocus={setActiveInput}
                      />
                    </Form.Field>
                  </Table.Cell>
                  <Table.Cell>
                    <Form.Field>
                      <input
                        required
                        readOnly={!formIsValid}
                        autoComplete={"off"}
                        id="lName"
                        name="lName"
                        type="text"
                        value={lastName}
                        onChange={onChangeForm}
                        placeholder="Last Name"
                        onFocus={setActiveInput}
                      />
                    </Form.Field>
                  </Table.Cell>
                  <Table.Cell>
                    <Form.Field>
                      <input
                        required
                        readOnly={!formIsValid}
                        autoComplete={"off"}
                        id="email"
                        name="email"
                        type="email"
                        value={email}
                        onChange={onChangeForm}
                        placeholder="Email"
                        onFocus={setActiveInput}
                      />
                    </Form.Field>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Form.Field>
                      <input
                        required
                        readOnly={!formIsValid}
                        autoComplete={"off"}
                        id="company"
                        name="company"
                        type="text"
                        onChange={onChangeForm}
                        value={company}
                        placeholder="Company"
                        onFocus={setActiveInput}
                      />
                    </Form.Field>
                  </Table.Cell>
                  <Table.Cell>
                    <Form.Group>
                      <Form.Field width="10">
                        <input
                          autoComplete={"off"}
                          id="carReg"
                          name="carReg"
                          type="text"
                          required
                          onChange={onChangeForm}
                          value={carReg}
                          className={
                            highlightInputBorderCarReg
                              ? " highlightInputBorder"
                              : ""
                          }
                          placeholder="Car Registration"
                          onFocus={setActiveInput}
                        />
                      </Form.Field>
                      <Form.Field width="6">
                        {temInstructionsFlag1 ? (
                          <input
                            autoComplete={"off"}
                            name="temp"
                            type="text"
                            required
                            onClick={openTempInstructionWin}
                            className={
                              highlightInputBorderTemp
                                ? " highlightInputBorder"
                                : ""
                            }
                            onChange={onChangeForm}
                            value={temperature}
                            placeholder="Temperature"
                            onFocus={setActiveInput}
                          />
                        ) : (
                          <input
                            autoComplete={"off"}
                            id="temperature"
                            name="temperature"
                            type="text"
                            required
                            className={
                              highlightInputBorderTemp
                                ? " highlightInputBorder"
                                : ""
                            }
                            onChange={onChangeForm}
                            value={temperature}
                            placeholder="Temperature"
                            onFocus={setActiveInput}
                          />
                        )}
                      </Form.Field>
                    </Form.Group>
                  </Table.Cell>
                  <Table.Cell>
                    <Form.Field>
                      <input
                        required
                        readOnly={!formIsValid}
                        autoComplete={"off"}
                        id="meetingWith"
                        name="meetingWith"
                        type="text"
                        onChange={onChangeForm}
                        value={meetingWith}
                        placeholder="Meeting With"
                        onFocus={setActiveInput}
                      />
                    </Form.Field>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Button
              size="big"
              fluid
              // primary
              style={{
                backgroundColor: "#4db25e",
                color: "#FFF",
                fontSize: 15,
                borderRadius: 5,
              }}
              disabled={!formIsValid && !carReg}
            >
              Proceed
            </Button>
          </Form>
        </Grid.Column>

        <Grid.Row centered>
          <Grid.Column width={11}>
            {errors && <ErrorMessage error={axiosResponse} text={errors} />}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <div className="keyboardPosition">
        <Keyboard
          inputName={inputName}
          useMouseEvents={true}
          keyboardRef={(r) => (keyboard.current = r)}
          layoutName={layout}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </div>
    </Fragment>
  );
};
export default observer(RegularVisitor);
