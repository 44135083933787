import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { IUser } from "../../../models/userModel";
interface IProps {
  activeItem: string;
  user: IUser;
}
const SuperAdminMenu: React.FC<IProps> = ({ activeItem, user }) => {
  return (
    <Menu vertical fluid>
      <Menu.Item
        content="Manage Visits"
        active={
     
          activeItem.includes("dashboard") || activeItem.includes("regular-vis")
     
        }
        as={Link}
        to="/dashboard"
      />
      <Menu.Item
        name="/register-partner"
        active={activeItem === "/register-partner"}
        as={Link}
        to="/register-partner"
      >
        Register Partner
      </Menu.Item>
      <Menu.Item
        content="Manage Tenant"
        active={activeItem.includes("/tenants")}
        as={Link}
        to={`/tenants`}
      />
      <Menu.Item
        content="Reports"
        active={activeItem.includes("report")|| activeItem.includes("/booked")}
        as={Link}
        to="/reports"
      />
    </Menu>
  );
};

export default SuperAdminMenu;
