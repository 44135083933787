import { action, observable } from "mobx";
import agent from "../API/agents";
import { RootStore } from "./rootStore";
import { IBadge, IBadgeData } from "../models/badge";
import { toast } from "react-toastify";


export default class BadgeStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable nextFromBadgeNumber: string = "";
  @observable singleBadgeFlag: boolean = true;

  @observable badgeData: IBadgeData | null = null

  @action badgeCheckboxChange = async () => {
    this.nextFromBadgeNumber = "";
    this.singleBadgeFlag = !this.singleBadgeFlag;

    if (this.rootStore.depotStore.depotId.length !== 0) this.GetBadgeNumber();
  };



  @action GetBadgeNumber = async () => {
    try {
      const result = await agent.badge.getBadge(this.rootStore.depotStore.depotId);
      this.nextFromBadgeNumber = result.toString();
    } catch (error) {
      throw error;
    }
  };

  @action AddBadge = async (data: IBadge) => {
    try {
      data.badgeNumFrom = this.nextFromBadgeNumber;
      const result = await agent.badge.create(data);
      if (result) {
        await this.rootStore.tenantStore.findTenant({
          tenantId: data.tenantId,
        });
        this.rootStore.modalStore.closeModal();
        this.nextFromBadgeNumber = "";
        this.singleBadgeFlag = true;
       
        delete data.badgeNum;
        delete data.badgeNumTo;
        toast.success("Badge added successfully!");
      }
    } catch (error) {
      throw error;
    }
  };

  @action FindNotCheckoutVisitorByBadgeNum = async (badgeBum: string, depotId: string) => {
    try {
      const result = await agent.badge.find(badgeBum, depotId);
      if(result)
      this.badgeData = result;
      
    } catch (error) {
      throw error;
    }
  };
}
