import React, { useState, useEffect, useContext } from "react";
import { Grid, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import AdminNavBar from "../layout/AdminNavBar";
import "../../style/dashboard.css";
import { RootStoreContext } from "../../stores/rootStore";
import SuperAdminMenu from './Menus/SuperAdminMenu'
import TenantAdminMenu from './Menus/TenantAdminMenu'
import UserMenu from './Menus/UserMenu'


interface IProps {
  children: any;
}
const Dashboard: React.FC<IProps> = ({ children }) => {
  const [activeItem, setActiveItem] = useState("/dashboard");
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.userStore;
  //const [roleArr, setRoleArr] = useState(["TenantAdmin", "SuperAdmin"]);
  useEffect(() => {
    setActiveItem(window.location.pathname);
  }, [setActiveItem]);

const AuthMenu = () => {
  switch (user?.role) {
    case "SuperAdmin":
      return <SuperAdminMenu user={user} activeItem={activeItem} />;
    case "TenantAdmin":
      return <TenantAdminMenu user={user} activeItem={activeItem} />;
    case "DepotManager":
      return <TenantAdminMenu user={user} activeItem={activeItem} />;
    default:
      return <UserMenu user={user!} activeItem={activeItem} />;
  }
};

  return (
    <div>
      <AdminNavBar />
      <Grid centered columns="2">
        <Grid.Column width="3">
          <AuthMenu />
        </Grid.Column>
        <Grid.Column width="13">
          <Segment>{children}</Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default observer(Dashboard);
