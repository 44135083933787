import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Segment, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/rootStore";

interface IProp {
  badgeNum: string;
  tenantId: string;
  depotId: string;
}

const ViewBadge: React.FC<IProp> = ({ badgeNum, tenantId, depotId }) => {
  const rootStore = useContext(RootStoreContext);
  const { FindNotCheckoutVisitorByBadgeNum, badgeData } = rootStore.badgeStore;
  const { ManualCheckOut } = rootStore.visitorStore;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    FindNotCheckoutVisitorByBadgeNum(badgeNum, depotId).then(() =>
      setLoading(false)
    );
  }, [FindNotCheckoutVisitorByBadgeNum, badgeNum, setLoading, depotId]);

  return (
    <div className="badge-details-container">
      <div className="badge-details-header">
        <h1>{badgeData?.depotBadgeNum}</h1>
      </div>
      {badgeData?.attendee.type === "Visitor" ? (
        <Segment loading={loading}>
          <Grid centered>
            <Grid.Row columns="2" centered className="badge-details-content">
              <Grid.Column width="8">
                <h2>Visitor Details</h2>
                <Table basic="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <strong>Reference:</strong>
                      </Table.Cell>
                      <Table.Cell>{badgeData?.visitReference}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <strong>Name:</strong>
                      </Table.Cell>
                      <Table.Cell>
                        {" "}
                        {badgeData?.attendee.firstName}{" "}
                        {badgeData?.attendee.lastName}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <strong>Email:</strong>
                      </Table.Cell>
                      <Table.Cell>{badgeData?.attendee.email}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column width="8">
                <h2>Host Details</h2>
                <Table basic="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <strong>Name:</strong>
                      </Table.Cell>
                      <Table.Cell>{badgeData?.host.name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <strong>Email:</strong>
                      </Table.Cell>
                      <Table.Cell>{badgeData?.host.email}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <strong>Department:</strong>
                      </Table.Cell>
                      <Table.Cell>{badgeData?.host.department}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns="2" className="badge-details-footer">
              <Grid.Column width="8">
                <Button
                  content="Check-out"
                  fluid
                  compact
                  color="red"
                  onClick={() => {
                    ManualCheckOut(
                      badgeData?.depotBadgeNum!,
                      depotId,
                      badgeData?.id!,
                      false,
                      tenantId
                    );
                  }}
                />
              </Grid.Column>
              <Grid.Column width="7">
                <Button
                  content="Send Notification & Check-out"
                  compact
                  fluid
                  onClick={() => {
                    ManualCheckOut(
                      badgeData?.depotBadgeNum!,
                      depotId,
                      badgeData?.id!,
                      true,
                      tenantId
                    );
                  }}
                  color="orange"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      ) : (
        <Segment loading={loading}>
          <Grid>
            <Grid.Row centered className="badge-details-content-partner">
              <Grid.Column width="14">
                <h2>Partner Details</h2>
                <Table basic="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <strong>Reference:</strong>
                      </Table.Cell>
                      <Table.Cell>{badgeData?.visitReference}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <strong>Name:</strong>
                      </Table.Cell>
                      <Table.Cell>{badgeData?.attendee.firstName}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <strong>Organization:</strong>
                      </Table.Cell>
                      <Table.Cell>{badgeData?.attendee.company}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered className="badge-details-footer">
              <Grid.Column width="15">
                <Button
                  content="Clock-out"
                  fluid
                  compact
                  color="red"
                  onClick={() => {
                    ManualCheckOut(
                      badgeData?.depotBadgeNum!,
                      depotId,
                      badgeData?.id!,
                      false,
                      tenantId
                    );
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      )}
    </div>
  );
};

export default ViewBadge;
