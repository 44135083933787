import React from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form, Label } from "semantic-ui-react";

interface IProps
  extends FieldRenderProps<string, HTMLElement>,
    FormFieldProps {}

const TextInput: React.FC<IProps > = ({
  input,
  type,
  label,
  placeholder, readOnly,
  maxLength,
  minLength,
  required,  

  meta: { touched, error },
}) => {
  return (
    <Form.Field  error={touched && !!error}  required={required}>
      <label>{label}</label>
      <input {...input} readOnly={readOnly} maxLength={maxLength} minLength={minLength}  placeholder={placeholder} />
      {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )}
    </Form.Field>
  );
};

export default TextInput;