import React, { useContext } from "react";
import {
    Table,
    Checkbox,
    CheckboxProps,
    Button,
    Modal,
    Divider,
    List,
    Form,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";

interface IProps {
    open: boolean;
    accept: (name: string) => void;
    denny: () => void;
}

const HouseRules: React.FC<IProps> = ({ open, accept, denny }) => {
    const rootStore = useContext(RootStoreContext);
    const {
        onchangeHouseRules,
        hr_1,
        hr_2,
        hr_3,hsr_5,
  
        sr_1,onchangeHealthSafetyRules,
        sr_2,
        hr_proceedBtn,

        CloseModalHR
    } = rootStore.visitorStore;

    return (
      <Modal
        className="house-rules-modal"
        open={open}
        closeOnEscape
        closeOnDimmerClick
        onClose={CloseModalHR}
        size="small"
      >
        <Modal.Header>House Rules</Modal.Header>
        <Modal.Content>
          <Table striped>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  Take note of and read our information signs, posters and
                  notice boards.
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    label="Accept"
                    name="hr_1"
                    checked={hr_1}
                    onChange={(
                      event: React.FormEvent<HTMLInputElement>,
                      data: CheckboxProps
                    ) => onchangeHouseRules(event, data)}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  If so requested, I agree to be temperature tested in advance
                  of my visit and will abide by all social distancing measures
                  whilst visiting.
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    label="Accept"
                    name="hr_2"
                    checked={hr_2}
                    onChange={(
                      event: React.FormEvent<HTMLInputElement>,
                      data: CheckboxProps
                    ) => onchangeHouseRules(event, data)}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Display your visitors pass at all times on the outside of your
                  clothes.
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    label="Accept"
                    name="hr_3"
                    checked={hr_3}
                    onChange={(
                      event: React.FormEvent<HTMLInputElement>,
                      data: CheckboxProps
                    ) => onchangeHouseRules(event, data)}
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  Please respect internal partitions within the warehouse for
                  your safety.
                </Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Checkbox
                      label="Accept"
                      value="yes"
                      name="hsr_5"
                      checked={hsr_5 === "yes"}
                      onChange={(
                        event: React.FormEvent<HTMLInputElement>,
                        data: CheckboxProps
                      ) => onchangeHealthSafetyRules(event, data)}
                    />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  During your visit security must be observed at all times,
                  please acknowledge the following:
                  <Divider horizontal />
                  <List as="ul" style={{ marginLeft: 80 }}>
                    <List.Item as="li">
                      From the moment you arrive, you are under CCTV
                      surveillance{" "}
                    </List.Item>
                    <List.Item as="li">
                      All entrance and exit points are guarded with physical
                      access control
                    </List.Item>
                    <List.Item as="li">
                      Do not be offended if challenged, staff members are
                      trained to do this
                    </List.Item>
                  </List>
                  <Divider horizontal />
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    label="Accept"
                    name="sr_1"
                    checked={sr_1}
                    onChange={(
                      event: React.FormEvent<HTMLInputElement>,
                      data: CheckboxProps
                    ) => onchangeHouseRules(event, data)}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Please do not force or enter any area without your escort.
                  When entering the Operations area, you must remain with your
                  escort at all times.
                </Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Checkbox
                      label="Accept"
                      name="sr_2"
                      value="yes"
                      checked={sr_2 === "yes"}
                      onChange={(
                        event: React.FormEvent<HTMLInputElement>,
                        data: CheckboxProps
                      ) => onchangeHouseRules(event, data)}
                    />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <div className="modalPageNum">
            <p>Page 1 of 2</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div className="modal-footer">
            <div className="action-text">
              <p>Your Security is important to us!</p>
            </div>

            <Button
              disabled={!hr_proceedBtn}
              positive
              onClick={() => accept("houseRules")}
              content="Proceed"
            />
          </div>
        </Modal.Actions>
      </Modal>
    );
};
export default observer(HouseRules);