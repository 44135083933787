import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import React, { useEffect, useContext, useState } from "react";
import { Menu } from "semantic-ui-react";
import Dashboard from "./Dashboard";
import { RootStoreContext } from "../../stores/rootStore";

interface IProps {
  children: any;
}
const ManageTenantLayout: React.FC<IProps> = ({ children }) => {
  const [activeItem, setActiveItem] = useState("/add-tenant");
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.userStore;
  useEffect(() => {
    setActiveItem(window.location.pathname);
  }, [setActiveItem]);

  const SuperAdminMenu = () => {
    return (
      <Menu pointing secondary>
        <Menu.Item
          name="/add-tenant"
          active={
            activeItem === "/manage-tenants" || activeItem === "/add-tenant"
          }
          as={Link}
          to="/add-tenant"
        >
          Add Tenant
        </Menu.Item>
        <Menu.Item
          name="/tenants"
          active={
            activeItem === "/tenants" || activeItem.includes("/tenant-details/")
          }
          as={Link}
          to="/tenants"
        >
          View Tenants
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Dashboard>
      {user?.role === "SuperAdmin" && <SuperAdminMenu />}
      {children}
    </Dashboard>
  );
};

export default observer(ManageTenantLayout);
